import { FC, ReactNode, useState, useEffect } from "react";
import Button from "../../components/Button";
import Link from "../../components/Link";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import es from "dayjs/locale/es";
import classNames from "classnames";
import {trackHelper} from '../../analytics/masterTracking.js';
import {
  CalendarIcon,
  ClockIcon,
  LocationMarkerIcon,
  ExclamationCircleIcon,
  CheckCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/solid";
import { UpcomingBookingsData } from "../HomeView";

export type UpcomingBookingProps = {
  data: UpcomingBookingsData;
};

const UpcomingBookingCard: FC<UpcomingBookingProps> = ({ data }) => {
  const payStatus = data.is_paid ? "paid" : "not paid";
  const paymentDate = data.payment_date;
  const locationLink = data.videocall_link
    ? data.videocall_link
    : data.office_address;
  const bookingStartDate = data.booking_start_time;
  const bookingEndDate = data.booking_end_time;
  const name = data.service_name;
  const bookingTimezone = data.timezone;
  const locationType = data.videocall_link ? "online" : "office_address";
  const location = data.office_address;
  const bookingToken = data.booking_token;
  const inTakeFormLink = data.intake_form_link;
  const scheduleLink = data.schedule_link;
  const paymentLink = data.payment_link;
  const price = data.price;
  const digitalContentLink = data.digital_content_link;
  const className = data.className;
  const secondContentOpen = data.secondContentOpen;
  const detailsFunction = data.detailsFunction;
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });
  const [detailsBtnLoader, setDetailsBtnLoader] = useState<boolean>(false);
  const now = dayjs();
  const bookingStartTime = dayjs(bookingStartDate).tz(bookingTimezone);
  const bookingEndTime = dayjs(bookingEndDate).tz(bookingTimezone);
  const bookingIsNotDone = now <= bookingEndTime;
  const getFormattedDateDay = (text?: string) => {
    return (
      dayjs(text).tz(bookingTimezone).format("D") +
      " de " +
      dayjs(text).tz(bookingTimezone).format("MMMM") +
      " del " +
      dayjs(text).tz(bookingTimezone).format("YYYY")
    );
  };
  const getFormattedDateHour = (text?: string) => {
    return (
      dayjs(text).tz(bookingTimezone).format("HH:mm") +
      " Hrs " +
      "(" +
      bookingTimezone +
      ")"
    );
  };

  const paymentStatusContainerClass = !payStatus
    ? "hidden"
    : payStatus === "paid" || price === 0
    ? "mt-3 flex w-full font-semibold text-sm items-center text-enc-success"
    : "mt-3 flex w-full font-semibold text-sm items-center text-enc-system-error";
  const bookingIsVeryClose =
    bookingStartTime.diff(now, "minutes") <= 10 &&
    bookingStartTime.diff(now, "minutes") > 0;

  // If you change the the following behaviour, you should also change it in BookingDetailsFooterBtn.tsx
  const locationBtnText = bookingIsVeryClose
    ? locationType === "online"
      ? "Ir a videollamada"
      : "Ver ubicación"
    : bookingIsNotDone && inTakeFormLink
    ? "Contestar formulario"
    : digitalContentLink
    ? "Abrir contenido"
    : payStatus === "paid" || price === 0
    ? "Agendar otra hora"
    : "Pagar";

  const handleRecentSessionsBtnClick = () => {
    let url = "";
    if (bookingIsVeryClose && locationType === "online" && locationLink) {
      url = locationLink;
    } else if (bookingIsVeryClose && locationType !== "online") {
      url = `https://www.google.cl/maps/place/${location?.replace(" ", "+")}`;
    } else if (bookingIsNotDone && inTakeFormLink) {
      url = inTakeFormLink;
    } else if (digitalContentLink) {
      url = digitalContentLink;
    } else if ((payStatus === "paid" || price === 0) && scheduleLink) {
      url = scheduleLink;
    } else if (paymentLink) {
      url = paymentLink;
    }
    window.open(url, "_blank");
    if (locationBtnText === 'Ir a videollamada' || locationBtnText === 'Ver ubicación') {
      trackHelper("External Link Opened",{
        'source_btn': 'Session Summary',
        'pressed_btn_name': locationBtnText   
      });
    }; 
  };
  useEffect(() => {
    if (secondContentOpen) {
      setDetailsBtnLoader(false);
    }
  }, [secondContentOpen]);
  const detailsBtnClass =
    "w-full text-enc-secondary-base flex justify-center hover:cursor-pointer hover:no-underline hover:bg-enc-secondary-background p-1 rounded-lg";

  return (
    <div
      className={classNames(
        "flex w-full flex-col rounded-xl border border-enc-background-light-gray p-4",
        className
      )}
    >
      <span className="font-medium text-enc-black text-base">{name}</span>
      {(location !== null || locationLink !== null) && (
        <div>
          <div className="mt-3 flex w-full font-normal text-sm text-enc-text-dark-gray items-center">
            <div className="mr-2 h-4 w-4">
              <CalendarIcon className="h-4 w-4 text-enc-secondary-light" />
            </div>
            <span className="font-light">
              {getFormattedDateDay(bookingStartDate)}
            </span>
          </div>
          <div className="mt-3 flex w-full font-normal text-sm text-enc-text-dark-gray items-center">
            <div className="mr-2 h-4 w-4">
              <ClockIcon className="h-4 w-4 text-enc-secondary-light" />
            </div>
            <span className="font-light">
              {getFormattedDateHour(bookingStartDate)}
            </span>
          </div>
          <div className="mt-3 flex w-full font-normal text-sm text-enc-text-dark-gray items-center">
            <div className="mr-2 h-4 w-4">
              {locationType === "online" ? (
                <VideoCameraIcon className="h-4 w-4 text-enc-secondary-light" />
              ) : (
                <LocationMarkerIcon className="h-4 w-4 text-enc-secondary-light" />
              )}
            </div>
            <span className="font-light">
              {locationType === "online" ? "Evento online" : location}
            </span>
          </div>
        </div>
      )}
      <div className={paymentStatusContainerClass}>
        <div className="mr-2 h-4 w-4">
          {payStatus &&
            (payStatus == "paid" || price === 0 ? (
              <CheckCircleIcon className="h-4 w-4"></CheckCircleIcon>
            ) : (
              <ExclamationCircleIcon className="h-4 w-4" />
            ))}
        </div>
        <span>
          {payStatus && payStatus === "paid" && !paymentDate ? (
            "Pagado"
          ) : payStatus && payStatus === "paid" && paymentDate ? (
            <span className="text-enc-success">
              Pagado el {getFormattedDateDay(paymentDate)}
            </span>
          ) : price === 0 ? (
            "Sin costo"
          ) : (
            "Por pagar"
          )}
        </span>
      </div>
      <div className="flex w-full flex-col sm:flex-row-reverse sm:justify-start sm:items-center">
        <div className="flex w-full mt-5 sm:mt-0 sm:w-56">
          <Button className="w-full" onClick={handleRecentSessionsBtnClick}>
            {locationBtnText}
          </Button>
        </div>
        {detailsBtnLoader ? (
          <div className="flex items-center justify-center animate-pulse  sm:mr-4 flex w-full sm:w-28 mt-4 sm:mt-0 ">
            <div className="w-2.5 h-2.5 bg-enc-secondary-base rounded-full ml-2"></div>
            <div className="w-2.5 h-2.5 bg-enc-secondary-base rounded-full ml-2"></div>
            <div className="w-2.5 h-2.5 bg-enc-secondary-base rounded-full ml-2"></div>
          </div>
        ) : (
          <div
            onClick={() =>
              detailsFunction && detailsFunction(bookingToken || "")
            }
            className="flex w-full mt-3 sm:mt-0 justify-center items-center sm:w-28 sm:mr-4"
          >
            <div onClick={() => {
              setDetailsBtnLoader(true);
              trackHelper('Sidebar Opened', {
                'click_source': 'Ver detalles', 
                'booking_is_not_done': bookingIsNotDone
              });
            }}>
              <Link className={detailsBtnClass} children="Ver detalles"></Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UpcomingBookingCard;
