import { FC } from "react";
import Card from "../Card";
import Loader from "../Loader";
import Link from "../../../../components/Link";
import { link_classes } from "../../../../styles";

type WelcomeCardProps = {
  loading: boolean;
  customerName: string;
  isHealthInsuranceConnected: boolean;
  healthInsuranceLink: string;
};

const WelcomeCard: FC<WelcomeCardProps> = ({
  loading,
  customerName,
  isHealthInsuranceConnected,
  healthInsuranceLink,
}) => {
  const welcomeCardLinkClasses = link_classes + " font-medium";
  return (
    <Card
      className="py-3"
      leftContent={
        <div className="mt-4 sm:mt-0 flex flex-col text-center items-center sm:items-start sm:text-left">
          <img
            className="h-8"
            src="https://uploads-ssl.webflow.com/603cb1f27d94f73ea44ed45f/61631ea26419a453f3e5687c_Logo%20Encuadrado.png"
            alt="Workflow"
          />
          {loading ? (
            <>
              <Loader className="mt-5 mb-6 w-60" />
              <Loader className="w-80 mb-2" />
              <Loader className="w-72" />
            </>
          ) : (
            <>
              <p className="text-base text-gray-600 font-semibold my-5">
                Nos alegra verte otra vez{" "}
                <span className="capitalize">{customerName.split(" ")[0]}</span>
              </p>
              <div className="w-3/5 mb-5">
                <p className="text-base text-gray-600">
                  En este lugar podrás ver todas las sesiones que has agendado a
                  través de nuestra plataforma.
                </p>
              </div>
              <Link
                className={welcomeCardLinkClasses}
                link={healthInsuranceLink}
              >
                {isHealthInsuranceConnected
                  ? "Cambiar datos de conexión de Isapre"
                  : "Conectar mi Isapre para reembolsos automáticos"}
              </Link>
            </>
          )}
        </div>
      }
    />
  );
};
export default WelcomeCard;
