import { XIcon } from "@heroicons/react/outline";
import { FC } from "react";
import BookingDetailsCardLocation from "./BookingDetailsCardLocation";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import es from "dayjs/locale/es";
import BookingDetailsFooterBtn from "./BookingDetailsFooterBtn";
import BookingDetailsSecondaryLinkBtns from "./BookingDetailsSecondaryLinkBtns";
import BookingDetailsTabsSection from "./BookingDetailsTabsSection";


export type BookingDetailsData = {
  isPaid?: boolean;
  startTime?: string;
  endTime?: string;
  bookingTimezone?: string;
  locationType?: string;
  location?: string;
  reviewLink?: string;
  invoiceLink?: string;
  duration?: number;
  name?: string;
  description?: string;
  inTakeFormLink?: string;
  paymentLink?: string;
  scheduleLink?: string;
  rescheduleLink?: string;
  professionalImgLink?: string;
  professionalName?: string;
  price?: number;
  digitalContentLink?: string;
};

export type BookingDetailsCard = {
  className?: string;
  data: BookingDetailsData;
  backBtnFunction: () => void;
  shown?: boolean;
};

const BookingDetailsCard: FC<BookingDetailsCard> = ({
  data,
  className,
  backBtnFunction,
  shown,
}) => {
  const {
    isPaid,
    startTime,
    bookingTimezone,
    endTime,
    locationType,
    location,
    reviewLink,
    invoiceLink,
    duration,
    name,
    description,
    inTakeFormLink,
    paymentLink,
    scheduleLink,
    rescheduleLink,
    professionalImgLink,
    professionalName,
    price,
    digitalContentLink
  } = data;
  const paidStatusClasses =
    isPaid || price === 0
      ? "py-0.5 px-4 bg-enc-bkg-system-success text-sm font-medium rounded-md text-enc-success"
      : "py-0.5 px-4 bg-enc-red-background text-sm font-medium rounded-md text-enc-error";
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });
  const now = dayjs();
  const bookingStartTime = dayjs(startTime).tz(bookingTimezone);
  const bookingEndTime = dayjs(endTime).tz(bookingTimezone);
  const boookingIsNotDone = now <= bookingEndTime;
  return (
    <div
      className={shown ? " sm:flex w-full flex-col" : " hidden sm:flex sm:w-0"}
    >
      <div
        onClick={() => backBtnFunction()}
        className={
          shown
            ? "fixed w-screen h-screen opacity-50 bg-enc-transparent z-30 hidden sm:flex"
            : "hidden"
        }
      ></div>
      <div
        className={
          shown
            ? "flex flex-col sm:fixed sm:transition sm:transition-[width] sm:dutation-500 sm:w-460px sm:h-full sm:right-0 sm:border sm:border-enc-background-light-gray sm:rounded-tl-lg sm:rounded-bl-lg sm:shadow-sm z-40 bg-white relative"
            : "flex flex-col sm:fixed sm:transition sm:transition-[width] sm:dutation-500 sm:w-0  sm:h-full sm:right-0 sm:border sm:border-enc-background-light-gray sm:rounded-tl-lg sm:rounded-bl-lg sm:shadow-sm z-40 bg-white relative"
        }
      >
        <div className="flex w-full h-full flex-col p-5 mb-24 overflow-y-auto">
          <div className="flex w-full justify-between mb-4">
            <div
              className="flex hover:cursor-pointer"
              onClick={() => backBtnFunction()}
            >
              <XIcon className="w-7 h-7 text-enc-secondary-light rounded-lg p-1 hover:bg-enc-background-salt-gray"></XIcon>
            </div>
            <div className={paidStatusClasses}>
              {isPaid ? "Pagado" : price === 0 ? "Sin costo" : "Por pagar"}
            </div>
          </div>
          <div className="mt-4 text-lg font-semibold">{name}</div>
          <BookingDetailsCardLocation
            classname="mt-3"
            location={location}
            locationType={locationType}
            duration={duration}
            startTime={startTime}
            bookingTimezone={bookingTimezone}
            professionalName={professionalName}
            price={price}
          ></BookingDetailsCardLocation>
          <BookingDetailsTabsSection
            BookingIsNotDone={boookingIsNotDone}
            inTakeFormLink={inTakeFormLink}
            invoiceLink={invoiceLink}
            isPaid={isPaid}
            reviewLink={reviewLink}
            paymentLink={paymentLink}
            scheduleLink={scheduleLink}
            startTime={startTime}
            endTime={endTime}
            bookingTimezone={bookingTimezone}
            rescheduleLink={rescheduleLink}
            price={price}
            digitalContentLink={digitalContentLink}
          ></BookingDetailsTabsSection>
          <div
            className={
              description
                ? " rounded-lg bg-enc-background-beige p-4 text-sm mt-4 flex"
                : "rounded-lg bg-enc-background-beige p-4 text-sm mt-4 hidden"
            }
          >
            {description}
          </div>
          {description && (
            <div
              className="ml-2.5 w-0 h-0 
          border-l-[5px] border-l-transparent
          border-t-[15px] border-t-enc-background-beige
          border-r-[10px] border-r-transparent"
            ></div>
          )}
          <div
            className={
              description
                ? "flex w-100 items-center mt-1 mb-4"
                : "flex w-100 items-center my-4"
            }
          >
            <div className="flex h-11 w-11 rounded-lg border mr-3">
              <img
                src={
                  professionalImgLink ||
                  "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
                }
                alt="name"
                height="auto"
                width="auto"
                className="object-contain"
              ></img>
            </div>
            <span className="font-semibold">{professionalName}</span>
          </div>
        </div>
        <BookingDetailsFooterBtn
          isPaid={isPaid}
          locationType={locationType}
          location={location}
          bookingIsNotDone={boookingIsNotDone}
          scheduleLink={scheduleLink}
          paymentLink={paymentLink}
          startTime={startTime}
          endTime={endTime}
          bookingTimezone={bookingTimezone}
          inTakeFormLink={inTakeFormLink}
          price={price}
          digitalContentLink={digitalContentLink}
        ></BookingDetailsFooterBtn>
      </div>
    </div>
  );
};
export default BookingDetailsCard;
