import { FC } from "react";
import Button from "../../components/Button";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import es from "dayjs/locale/es";
import {trackHelper} from '../../analytics/masterTracking.js';

export type BookingDetailsFooterBtnProps = {
  className?: string;
  isPaid?: boolean;
  locationType?: string;
  location?: string;
  bookingIsNotDone?: boolean;
  scheduleLink?: string;
  paymentLink?: string;
  startTime?: string;
  bookingTimezone?: string;
  endTime?: string;
  inTakeFormLink?: string;
  price?: number;
  digitalContentLink?: string;
};

const BookingDetailsFooterBtn: FC<BookingDetailsFooterBtnProps> = ({
  className,
  isPaid,
  locationType,
  location,
  bookingIsNotDone,
  scheduleLink,
  paymentLink,
  startTime,
  bookingTimezone,
  endTime,
  inTakeFormLink,
  price,
  digitalContentLink,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });
  const now = dayjs();
  const bookingStartTime = dayjs(startTime).tz(bookingTimezone);
  const bookingIsVeryClose =
    bookingStartTime.diff(now, "minutes") <= 10 &&
    bookingStartTime.diff(now, "minutes") > 0;
  const locationBtnText = bookingIsVeryClose
    ? locationType === "online"
      ? "Ir a videollamada"
      : "Ver ubicación"
    : bookingIsNotDone && inTakeFormLink
    ? "Contestar formulario"
    : digitalContentLink
    ? "Abrir contenido"
    : bookingIsNotDone && inTakeFormLink
    ? "Contestar formulario"
    : isPaid || price === 0
    ? "Agendar otra hora"
    : "Pagar";
  const handleRecentSessionsBtnClick = () => {
    let url = "";
    if (bookingIsVeryClose && locationType === "online" && location) {
      url = location;
    } else if (bookingIsVeryClose && locationType !== "online") {
      url = `https://www.google.cl/maps/place/${location?.replace(" ", "+")}`;
    } else if (bookingIsNotDone && inTakeFormLink) {
      url = inTakeFormLink;
    } else if (digitalContentLink) {
      url = digitalContentLink;
    } else if ((isPaid || price === 0) && scheduleLink) {
      url = scheduleLink;
    } else if (paymentLink) {
      url = paymentLink;
    }
    window.open(url, "_blank");
    if (locationBtnText === 'Ir a videollamada' || locationBtnText === 'Ver ubicación') {
      trackHelper("External Link Opened",{
        'source_btn': 'Session Details',
        'pressed_btn_name': locationBtnText   
      });
    }; 
  }

  // If you change the the following behaviour, you should also change it in the variable sidebarPrimaryBtnLink at BookingDetailsTabSection.tsx 
  // and at locationBtnText (and where it's used) in UpcoomingBookingCard.tsx
  return (
    <div className="flex w-full bottom-0 fixed p-5 shadow-footer-button z-10 bg-white sm:rounded-bl-lg sm:absolute">
      <Button className="w-full" onClick={handleRecentSessionsBtnClick}>
        {locationBtnText}
      </Button>
    </div>
  );
};

export default BookingDetailsFooterBtn;
