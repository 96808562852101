import { FC, useState, useRef, useEffect, RefObject } from "react";
import {
  StarIcon,
  CalendarIcon,
  ViewListIcon,
  DocumentTextIcon,
  CreditCardIcon,
  ChevronDownIcon,
  ArrowLeftIcon,
} from "@heroicons/react/outline";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import es from "dayjs/locale/es";
import Link from "../../components/Link";
import { SwitchHorizontalIcon } from "@heroicons/react/solid";

export type BookingDetailsTabsSectionProps = {
  className?: string;
  BookingIsNotDone?: boolean;
  invoiceLink?: string;
  isPaid?: boolean;
  inTakeFormLink?: string;
  reviewLink?: string;
  scheduleLink?: string;
  paymentLink?: string;
  startTime?: string;
  bookingTimezone?: string;
  endTime?: string;
  rescheduleLink?: string;
  price?: number;
  digitalContentLink?: string;
};

export const useOutsideClick = (callback: () => void) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [callback]);

  return ref;
};

const BookingDetailsTabsSection: FC<BookingDetailsTabsSectionProps> = ({
  className,
  BookingIsNotDone,
  inTakeFormLink,
  invoiceLink,
  isPaid,
  reviewLink,
  scheduleLink,
  paymentLink,
  startTime,
  bookingTimezone,
  endTime,
  rescheduleLink,
  price,
  digitalContentLink,
}) => {
  const redirectOnClick = (text: string) => {
    window.open(text, "_blank");
  };
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false);

  const cardClass =
    "rounded-2xl flex w-full border-enc-light-gray border p-2 text-enc-text-dark-gray mr-3 items-center justify-center hover:cursor-pointer hover:bg-enc-background-salt-gray";
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });
  const now = dayjs();
  const bookingStartTime = dayjs(startTime).tz(bookingTimezone);
  const bookingEndTime = dayjs(endTime).tz(bookingTimezone);
  const bookingIsNotDone = now <= bookingEndTime;
  const bookingIsVeryClose =
    bookingStartTime.diff(now, "minutes") <= 10 &&
    bookingStartTime.diff(now, "minutes") > 0;
  const dropdownRef = useOutsideClick(() => {
    {
      setDropdownMenuOpen(false);
    }
  });
  const primartyBtnIconClass = "mr-4 w-6 h-6";

  const primaryBtnLink =
    bookingIsNotDone && inTakeFormLink && bookingIsVeryClose
      ? inTakeFormLink
      : bookingIsNotDone &&
        !isPaid &&
        price !== 0 &&
        ((bookingIsVeryClose && !inTakeFormLink) ||
          (!bookingIsVeryClose && inTakeFormLink))
      ? paymentLink
      : bookingIsNotDone && !bookingIsVeryClose && !inTakeFormLink && rescheduleLink
      ? rescheduleLink
      : !bookingIsNotDone && reviewLink
      ? reviewLink
      : invoiceLink
      ? invoiceLink
      : scheduleLink;

  const primaryBtnText =
    bookingIsNotDone && inTakeFormLink && bookingIsVeryClose
      ? "Completar formulario"
      : bookingIsNotDone &&
        !isPaid &&
        price !== 0 &&
        ((bookingIsVeryClose && !inTakeFormLink) ||
          (!bookingIsVeryClose && inTakeFormLink))
      ? "Pagar"
      : bookingIsNotDone && !bookingIsVeryClose && !inTakeFormLink && rescheduleLink
      ? "Reagendar"
      : !bookingIsNotDone && reviewLink
      ? "Evaluar"
      : invoiceLink
      ? "Ver boleta"
      : "Agendar otra hora";

  const primaryBtnIcon =
    bookingIsNotDone && inTakeFormLink && bookingIsVeryClose ? (
      <ViewListIcon className={primartyBtnIconClass}></ViewListIcon>
    ) : bookingIsNotDone &&
      !isPaid &&
      price !== 0 &&
      ((bookingIsVeryClose && !inTakeFormLink) ||
        (!bookingIsVeryClose && inTakeFormLink)) ? (
      <CreditCardIcon className={primartyBtnIconClass}></CreditCardIcon>
    ) : bookingIsNotDone && !bookingIsVeryClose && !inTakeFormLink && rescheduleLink ? (
      <SwitchHorizontalIcon
        className={primartyBtnIconClass}
      ></SwitchHorizontalIcon>
    ) : !bookingIsNotDone && reviewLink ? (
      <StarIcon className={primartyBtnIconClass}></StarIcon>
    ) : invoiceLink ? (
      <DocumentTextIcon className={primartyBtnIconClass}></DocumentTextIcon>
    ) : (
      <CalendarIcon className={primartyBtnIconClass}></CalendarIcon>
    );

  // If you change the sidebarPrimaryBtnLink's behaviour, you should also change it in BookingDetailsFooterBtn.tsx
  const sidebarPrimaryBtnLink =
    bookingIsNotDone && inTakeFormLink
      ? inTakeFormLink
      : digitalContentLink
      ? digitalContentLink
      : isPaid || price === 0
      ? scheduleLink
      : paymentLink;

  const actionLinks = [];
  if (
    invoiceLink &&
    invoiceLink !== primaryBtnLink &&
    invoiceLink !== sidebarPrimaryBtnLink
  ) {
    actionLinks.push({
      link: invoiceLink,
      text: "Ver boleta",
    });
  }
  if (
    bookingIsNotDone &&
    rescheduleLink &&
    rescheduleLink !== primaryBtnLink &&
    rescheduleLink !== sidebarPrimaryBtnLink
  ) {
    actionLinks.push({
      link: rescheduleLink,
      text: "Reagendar",
    });
  }
  if (
    inTakeFormLink &&
    inTakeFormLink !== primaryBtnLink &&
    inTakeFormLink !== sidebarPrimaryBtnLink
  ) {
    actionLinks.push({
      link: rescheduleLink,
      text: "Completar formulario",
    });
  }
  if (
    scheduleLink !== primaryBtnLink &&
    scheduleLink !== sidebarPrimaryBtnLink
  ) {
    actionLinks.push({
      link: scheduleLink,
      text: "Agendar otra hora",
    });
  }
  if (
    !isPaid &&
    price !== 0 &&
    paymentLink !== primaryBtnLink &&
    paymentLink !== sidebarPrimaryBtnLink
  ) {
    actionLinks.push({
      link: paymentLink,
      text: "Pagar",
    });
  }

  return (
    <div className="flex w-full mt-4">
      <div
        onClick={() => redirectOnClick(primaryBtnLink || "")}
        className={cardClass}
      >
        {primaryBtnIcon}
        <span className=" text-sm">{primaryBtnText}</span>
      </div>
      <div
        ref={dropdownRef}
        onClick={() => setDropdownMenuOpen(!dropdownMenuOpen)}
        className="flex relative"
      >
        <div
          className={`rounded-2xl relative flex w-20 border-enc-light-gray border p-2 text-enc-text-dark-gray items-center justify-center hover:cursor-pointer hover:bg-enc-background-salt-gray ${
            actionLinks.length === 0 ? "hidden" : ""
          }`}
        >
          <span className="text-sm">Más</span>
          <ChevronDownIcon className="w-6 h-6"></ChevronDownIcon>
        </div>
        <div
          className={
            dropdownMenuOpen
              ? cardClass +
                " absolute top-14 z-20 w-56 right-0 mr-0 !bg-white flex-col p-2 shadow-sm"
              : "hidden"
          }
        >
          {actionLinks?.map((action) =>
            action.link ? (
              <Link
                className="p-2 text-sm rounded-lg w-full text-enc-text-dark-gray hover:cursor-pointer hover:bg-enc-background-salt-gray hover:no-underline"
                link={action.link}
              >
                {action.text}
              </Link>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsTabsSection;
