import { FC, useState, useEffect } from 'react';
import { CalendarIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { ParamsProps } from './HomeView';
import ProfessionalListCard from './Customers/ProfessionalListCard';
import axios from 'axios';
import UpcomingBookingCard from './Customers/UpcomingBookingCard';
import { IBookingData } from './Customers/BookingsHistory/BookingsList';
import { BookingDetailsData } from './HomeView';
import BookingDetailsCard from './Customers/BookingDetailsCard';
import Pagination from '../components/Pagination';
import { CustomerData } from './Customers/BookingsHistory/ProfessionalsList';
import { trackPageView } from '../analytics/masterTracking.js';
import NavbarWithHeader from '../components/templates/NavbarWithHeader';

export type ProfessionalSessionsViewProps = {
  className?: string;
};

const ProfessionalSessionsView: FC<ProfessionalSessionsViewProps> = () => {
  const defaultBookingsData: IBookingData = {
    bookings: [],
    professional_data: {
      professional_booking_link: "",
      professional_business_type: "",
      professional_email: "",
      professional_image_url: "",
      professional_name: "",
    },
  };
  const primartyBtnIconClass = "mr-4 w-6 h-6";
  const { token, professionalEmail } = useParams<ParamsProps>();
  const [secondContentOpen, setSecondContentOpen] = useState(false);
  const [bookingsData, setBookingsData] =
    useState<IBookingData>(defaultBookingsData);
  const [bookingDetailsData, setBookingDetailsData] =
    useState<BookingDetailsData>({});
  const [customerData, setCustomerData] = useState<CustomerData>();
  const baseURL = `https://encuadrado.com/api/v1/bookings/${professionalEmail}`;
  const bookingDetailsURL = "https://encuadrado.com/api/v1/get-booking-details";
  const customerURL = "https://encuadrado.com/api/v1/customer";

  useEffect(() => {
    document.title = 'Tu profesional | Portal';
  }, []);
  
  useEffect(() => {
    axios
      .post<IBookingData>(
        baseURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setBookingsData(response.data);
      })
      .catch((ex) => {});
    axios
      .post<CustomerData>(
        customerURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPageView();
  }, []);

  const getBookingDetails = (bookingToken: string) => {
    axios
      .post<BookingDetailsData>(
        bookingDetailsURL,
        {
          client_email_token: token,
          booking_token: bookingToken,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setSecondContentOpen(true);
        setBookingDetailsData(response.data);
      })
      .catch((ex) => {
        setSecondContentOpen(true);
      });
  };

  return (
    <>
      <BookingDetailsCard
        shown={secondContentOpen}
        data={bookingDetailsData}
        backBtnFunction={() => setSecondContentOpen(false)}
      ></BookingDetailsCard>
      <NavbarWithHeader
        containerClasses='p-4'
        healthInsuranceURL={
          customerData?.link_onboarding_health_insurance_provider || ''
        }
        selectedBtn='home'
      >
        <div className='flex w-full flex-col lg:flex-row mb-6'>
          {bookingsData && bookingsData.professional_data && (
            <ProfessionalListCard
              name={bookingsData.professional_data.professional_name}
              profession={
                bookingsData.professional_data.professional_business_type
              }
              link={bookingsData.professional_data.professional_booking_link}
              imgLink={bookingsData.professional_data.professional_image_url}
              hasDebt='true'
              specificCard={true}
              professionalEmail={
                bookingsData['professional_data'].professional_email
              }
            ></ProfessionalListCard>
          )}
          <div
            className='mt-3 rounded-2xl flex w-full border-enc-light-gray border p-3 text-enc-text-dark-gray mr-3 items-center justify-center hover:cursor-pointer hover:bg-enc-background-salt-gray sm:w-72 sm:h-11'
            onClick={() =>
              window.location.assign(
                bookingsData['professional_data'].professional_booking_link
              )
            }
          >
            <CalendarIcon className={primartyBtnIconClass}></CalendarIcon>
            <span className='text-m'>Ver servicios</span>
          </div>
        </div>

        <div className='mt-3 w-full flex justify-center hidden'>
          <div className='w-full flex flex-col justify-center items-center'>
            <span className='text-enc-primary-base'>
              {bookingsData.bookings.length + ' sesiones'}
            </span>
            <hr></hr>
          </div>
          <div className='border-r ml-3 border-enc-primary-base'></div>
          <div className='w-full flex flex-col justify-center items-center'>
            <span className='ml-4 text enc-secondary-base'>
              {bookingsData.bookings.filter((booking) => booking.boleta_link)
                .length != 1
                ? bookingsData.bookings.filter((booking) => booking.boleta_link)
                    .length + ' boletas'
                : bookingsData.bookings.filter((booking) => booking.boleta_link)
                    .length + ' boleta'}
            </span>
            <hr></hr>
          </div>
          <hr></hr>
        </div>
        <div className='flex w-full flex-col'>
          {bookingsData && bookingsData['bookings'].length > 5 ? (
            <Pagination
              data={bookingsData.bookings.map((booking) => ({
                ...booking,
                className: 'mb-4',
                detailsFunction: getBookingDetails,
                secondContentOpen: secondContentOpen,
              }))}
              component={UpcomingBookingCard}
              dataLimit={4}
            />
          ) : (
            bookingsData['bookings']?.map((booking) => (
              <UpcomingBookingCard
                data={{
                  ...booking,
                  className: 'mb-4',
                  detailsFunction: getBookingDetails,
                  secondContentOpen: secondContentOpen,
                }}
              />
            ))
          )}
        </div>
      </NavbarWithHeader>
    </>
  );
};

export default ProfessionalSessionsView;
