import { FC } from "react";
import Card from "../Card";
import { useParams } from "react-router-dom";
import Tag from "../Tag";
import { ParamsProps } from "../utils";
import { Link } from "react-router-dom";
import LeftContent from "../LeftContent";
import { ProfessionalData } from "./utils";
import { left_content_first_text, left_content_second_text } from "./styles";
import { link_classes } from "../../../../styles";

type ProfessionalCardProp = {
  data: ProfessionalData;
};

const ProfessionalCard: FC<ProfessionalCardProp> = ({ data }) => {
  const {
    professional_image_url,
    professional_business_type,
    professional_name,
    has_debt,
    professional_email,
  } = data;
  const { token } = useParams<ParamsProps>();

  return (
    <Card
      leftContent={
        <LeftContent
          img={professional_image_url}
          firstText={professional_business_type}
          firstTextClass={left_content_first_text}
          secondText={professional_name}
          secondTextClass={left_content_second_text}
        />
      }
      rightContent={<Tag debt={has_debt} />}
      footer={{
        color: "gray-50",
        leftContent: (
          <Link
            to={`/${token}/profesionales/${professional_email}/sesiones`}
            className={link_classes}
          >
            Ver mis sesiones
          </Link>
        ),
      }}
    />
  );
};
export default ProfessionalCard;
