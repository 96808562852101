import { FC } from "react";
import GeneralView from "../GeneralView";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";

const Error404: FC = () => {
  const navigate = useNavigate();
  return (
    <GeneralView
      title="Esta página no existe"
      subtitle="Puedes conseguir tu link de acceso a tu cuenta aquí."
    >
      <Button className="mt-9" onClick={() => navigate("/ingreso-mail")}>
        Ir a ingresar mail
      </Button>
    </GeneralView>
  );
};

export default Error404;
