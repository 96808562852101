import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import Button from '../../../../components/Button';
import Header from '../../../../components/Header';
import Input from '../../../../components/Input';

export type EditPersonalInfoViewProps = {
  className?: string;
};

type FormType = {
  email: string;
};

const EditPersonalInfoView: FC<EditPersonalInfoViewProps> = () => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm<FormType>();
  const { token } = useParams<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const url = 'https://encuadrado.com/api/v1/update-customer-email';

  const sendEmail = (data: FormType) => {
    setLoading(true);
    axios
      .patch<string>(url, {
        old_email_token: token,
        new_email: data.email,
      })
      .then(({ data }: any) => {
        const { token } = data;
        setLoading(false);
        navigate(`/${token}/configuraciones`);
      })
      .catch((ex) => {
        const error = ex.response.status;
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className='flex flex-col'>
      <Header secondContentOpen={false}></Header>
      <div className='flex justify-center px-8 pt-5 mt-64px w-full'>
        <div className='flex flex-col w-full sm:max-w-460px'>
          <span className='text-lg font-medium mb-4'>
            Editar información personal
          </span>
          <form className='w-full' onSubmit={handleSubmit(sendEmail)}>
            <Input
              {...register('email', { required: true })}
              label='Correo'
              type='email'
              name='email'
              id='email'
            />
            <div className='flex justify-end w-full mt-4'>
              <Button
                className='mr-4'
                encType='secondary'
                onClick={() => navigate(`/${token}/configuraciones`)}
              >
                Cancelar
              </Button>
              <Button type='submit' loading={loading} disabled={loading}>
                Guardar
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditPersonalInfoView;
