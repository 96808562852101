import { FC } from "react";
import Card from "../Card";
import Button from "./Button";
import LeftContent from "../LeftContent";
import { ProfessionalData } from "./utils";
import Loader from "../Loader";

type ProfessionalCardProps = {
  loading?: boolean;
  data: ProfessionalData;
  sessionsQuantity: number;
};

const ProfessionalCard: FC<ProfessionalCardProps> = ({
  loading,
  data,
  sessionsQuantity,
}) => {
  const {
    professional_image_url,
    professional_business_type,
    professional_name,
    professional_email,
    professional_booking_link,
  } = data;

  return (
    <Card
      footer={{
        color: "white",
        middleContent: loading ? (
          <Loader className="w-32 h-6" />
        ) : (
          `llevas ${sessionsQuantity} ${
            sessionsQuantity === 1 ? "sesión" : "sesiones"
          }`
        ),
      }}
    >
      <div className="flex flex-col sm:flex-row sm:justify-between">
        <LeftContent
          loading={loading}
          img={professional_image_url}
          containerClassName="flex-col-reverse sm:flex-row text-center"
          firstText={`${professional_name} - ${professional_business_type}`}
          firstTextClass="font-bold text-21px text-gray-600"
          secondText={professional_email}
          secondTextClass="font-medium text-base text-gray-500 mb-18px sm:mt-0"
        />

        <Button
          loading={loading}
          linkTo={professional_booking_link}
          text="Agendar otra Sesión"
        />
      </div>
    </Card>
  );
};

export default ProfessionalCard;
