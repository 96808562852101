import { FC, useState } from 'react';
import Header from '../Header';
import EncNavbar from '../Navbar/index';

export type NavbarWithHeaderProps = {
  className?: string;
  containerClasses?: string;
  healthInsuranceURL: string;
  selectedBtn: 'home' | 'attentions' | 'reimbursements' | 'help' | 'settings';
};

const NavbarWithHeader: FC<NavbarWithHeaderProps> = ({
  children,
  containerClasses,
  ...rest
}) => {
  const [isNavbarOpen, setIsNavbarOpen] = useState(
    window.innerWidth > 640 ? true : false
  );

  return (
    <div className='flex flex-col'>
      <Header
        clickMenu={() => setIsNavbarOpen(!isNavbarOpen)}
        navbarOpen={isNavbarOpen}
        secondContentOpen={false}
      ></Header>
      <EncNavbar
        visible={isNavbarOpen}
        setIsNavbarOpen={setIsNavbarOpen}
        {...rest}
      />
      <div className={`${containerClasses} flex flex-col p-5 mt-64px sm:ml-52`}>
        {children}
      </div>
    </div>
  );
};

export default NavbarWithHeader;
