import { FC } from "react";
import GeneralView from "../../GeneralView";
import { Link } from "react-router-dom";
import { link_classes } from "../../../styles";

const ConfirmationView: FC = () => {
  return (
    <GeneralView
      title="Revisa tu mail"
      subtitle={
        <>
          <p className="mb-10">
            Te enviamos el link de acceso a tus sesiones de Encuadrado. Recuerda
            revisar tu sección de spam si no lo encuentras.
          </p>
          <Link className={link_classes} to={"/ingreso-mail"}>
            ¿No recibiste el mail?
          </Link>
        </>
      }
      professionalLogin={false}
    />
  );
};

export default ConfirmationView;
