import { FC } from "react";
import classNames from "classnames";
import { link_classes } from "../styles";

export type LinkProps = {
  link?: string;
  className?: string;
  inSamePage?: boolean;
};

const Link: FC<LinkProps> = ({ link, children, className, inSamePage }) => {
  const linkClasses = classNames(link_classes, className);
  return (
    <a
      href={link}
      target={!inSamePage ? "_blank" : ""}
      className={linkClasses}
    >
      {children}
    </a>
  );
};

export default Link;
