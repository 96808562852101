import { FC } from "react";
import { BrowserRouter, Routes as Switch, Route } from "react-router-dom";
import ProffesionalsList from "./views/Customers/BookingsHistory/ProfessionalsList";
import BookingsList from "./views/Customers/BookingsHistory/BookingsList";
import ConfirmationView from "./views/Customers/Login/ConfirmationView";
import TokenRequest from "./views/Customers/Login/TokenRequest";
import Error404 from "./views/Errors/Error404";
import HomeView from "./views/HomeView";
import ConfigurationView from './views/Customers/Configurations';
import ProfessionalListView from "./views/ProfessionalListView";
import ActivityView from "./views/ActivityView";
import ProfessionalSessionsView from "./views/ProfessionalSessionsView";
import LogoutView from "./views/Customers/Login/LogoutView";
import EditPersonalInfoView from "./views/Customers/Configurations/PersonalInfo/EditView";

const Routes: FC = () => (
  <BrowserRouter>
    <Switch>
      <Route 
        path="/ingreso-mail" 
        element={<TokenRequest />}
      ></Route>
      <Route 
        path="/confirmacion" 
        element={<ConfirmationView />}
      ></Route>
      <Route 
        path="/:token/home"
        element={<HomeView></HomeView>}
      ></Route>
      <Route path="/:token/home/logout" element={<LogoutView />}></Route>
      <Route
        path="/:token/configuraciones"
        element={<ConfigurationView></ConfigurationView>}
      ></Route>
      <Route
        path="/:token/configuraciones/informacion-personal/editar"
        element={<EditPersonalInfoView></EditPersonalInfoView>}
      ></Route>
      <Route
        path="/:token/home/profesionales"
        element={<ProfessionalListView></ProfessionalListView>}
      ></Route>
      <Route
        path="/:token/home/actividad"
        element={<ActivityView></ActivityView>}
      ></Route>
      <Route
        path="/:token/profesionales"
        element={<ProffesionalsList />}
      ></Route>
      <Route
        path="/:token/home/profesionales/:professionalEmail/sesiones"
        element={<ProfessionalSessionsView />}
      ></Route>
      <Route
        path="/:token/profesionales/:professionalEmail/sesiones"
        element={<BookingsList />}
      ></Route>
      <Route path="*" element={<Error404 />}></Route>
    </Switch>
  </BrowserRouter>
);

export default Routes;
