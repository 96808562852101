import { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export type EncNavbarBtnProps = {
  className?: string;
  icon?: ReactNode;
  selected?: boolean;
  url: string;
  newTab?: boolean;
};

const EncNavbarBtn: FC<EncNavbarBtnProps> = ({
  className,
  icon,
  selected,
  url,
  newTab,
  children,
}) => {
  const navigate = useNavigate();

  const btnClasses = classNames(
    className,
    'flex items-center justyfy-start  p-2 rounded-2xl',
    selected
      ? 'text-enc-primary-base hover:bg-enc-primary-background'
      : 'text-enc-secondary-base hover:bg-enc-secondary-background'
  );

  const handleClick = () => {
    newTab ? window.open(url, '_blank') : navigate(url);
  };
  return (
    <button onClick={handleClick} className={btnClasses}>
      <div className='w-5 h-5 flex items-center mr-3'>{icon}</div>
      <span className='text-sm font-medium'>{children}</span>
    </button>
  );
};

export default EncNavbarBtn;
