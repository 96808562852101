import { useState, ReactElement, ElementType } from "react";
import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from "@heroicons/react/solid";

type PaginationProps<T extends object> = {
  data: T[];
  component: ElementType;
  dataLimit: number;
};

const Pagination = <D extends object>({
  data,
  component: Component,
  dataLimit,
}: PaginationProps<D>): ReactElement => {
  const pages = Math.ceil(data.length / dataLimit);
  const [currentPage, setCurrentPage] = useState<number>(1);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  function changePage(event: any) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
  };

  const getPaginationGroup = () => {
    if (pages <= 2)
      return new Array(pages).fill(undefined).map((_, idx) => idx + 1);
    else if (currentPage >= pages - 1)
      return new Array(3).fill(undefined).map((_, idx) => idx + pages - 2);
    else if (currentPage <= 2)
      return new Array(3).fill(undefined).map((_, idx) => idx + 1);
    return new Array(3).fill(undefined).map((_, idx) => idx + currentPage - 1);
  };

  return (
    <div>
      <div>
        {getPaginatedData().map((d, idx) => (
          <Component key={idx} data={d} />
        ))}
      </div>
      <nav className="my-5 border-t border-gray-200 sm:px-4 flex items-center justify-between sm:px-0">
        <div className="-mt-px w-0 flex-1 flex">
          <button
            onClick={goToPreviousPage}
            className="border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 disabled:text-gray-400 disabled:border-0"
            disabled={currentPage === 1}
          >
            <ArrowNarrowLeftIcon
              className="mr-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            <span className="hidden sm:flex">Anterior </span>
          </button>
        </div>
        <div className="md:-mt-px md:flex">
          {pages > 3 && currentPage >= 3 && (
            <button
              key={1}
              onClick={changePage}
              className={`border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium ${
                currentPage === 1
                  ? "border-enc-blue-500 text-enc-blue-500"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              <span>{1}</span>
            </button>
          )}
          {currentPage > 3 && pages > 4 && (
            <span className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
              ...
            </span>
          )}
          {getPaginationGroup().map((item, index) => (
            <button
              key={index}
              onClick={changePage}
              className={`border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium ${
                currentPage === item
                  ? "border-enc-blue-500 text-enc-blue-500"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              <span>{item}</span>
            </button>
          ))}

          {pages > 4 && currentPage < pages - 2 && (
            <span className="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium">
              ...
            </span>
          )}
          {pages > 3 && currentPage < pages - 1 && (
            <button
              key={pages}
              onClick={changePage}
              className={`border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium ${
                currentPage === pages
                  ? "border-enc-blue-500 text-enc-blue-500"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
              }`}
            >
              <span>{pages}</span>
            </button>
          )}
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          <button
            onClick={goToNextPage}
            className="border-t-2 border-transparent pt-4 pl-1 inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 disabled:text-gray-400 disabled:border-0"
            disabled={currentPage === pages}
          >
            <span className="hidden sm:flex"> Siguiente </span>
            <ArrowNarrowRightIcon
              className="ml-3 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;
