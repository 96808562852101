import { FC } from "react";
import classNames from "classnames";
import { useNavigate, useParams } from "react-router-dom";
import { ParamsProps } from "../HomeView";

export type ProfessionalFooterCardProps = {
  imageLink?: string;
  name?: string;
  profession?: string;
  className?: string;
  professionalEmail?: string;
};

const ProfessionalFooterCard: FC<ProfessionalFooterCardProps> = ({
  imageLink,
  name,
  profession,
  className,
  professionalEmail,
}) => {
  const cardClasses = classNames(
    "flex flex-col items-center bg-white",
    className
  );
  const navigate = useNavigate();
  const { token } = useParams<ParamsProps>();
  return (
    <div className={cardClasses}>
      <div
        className={
          cardClasses +
          "sm:flex sm:w-52 sm:rounded-2xl  sm:border-enc-secondary-base hover:brightness-[0.85] cursor-pointer"
        }
        onClick={() =>
          navigate(
            `/${token || ""}/home/profesionales/${
              professionalEmail || ""
            }/sesiones`
          )
        }
      >
        <div className="flex h-52 w-52 rounded-2xl border border-enc-light-gray justify-center items-center relative">
          <img
            src={imageLink}
            alt={name}
            height="206px"
            width="auto"
            className="object-contain rounded-2xl h-52 w-52"
          ></img>
          <div className="flex absolute w-52 rounded-b-2xl border border-enc-light-gray bottom-0 p-2 flex-col bg-white">
            <span className="font-semibold text-enc-black mt-2">{name}</span>
            <span className="text-enc-secondary-base text-normal text-small">
              {profession}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalFooterCard;
