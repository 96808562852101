import { FC } from "react";
import classNames from "classnames";
import {
  ClockIcon,
  VideoCameraIcon,
  LocationMarkerIcon,
  CalendarIcon,
  UserIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/solid";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import es from "dayjs/locale/es";

export type BookingDetailsCardLocationProps = {
  classname?: string;
  startTime?: string;
  location?: string;
  locationType?: string;
  duration?: number;
  bookingTimezone?: string;
  professionalName?: string;
  price?: number;
};

const BookingDetailsCardLocation: FC<BookingDetailsCardLocationProps> = ({
  classname,
  startTime,
  location,
  locationType,
  duration,
  bookingTimezone,
  professionalName,
  price,
}) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });
  const now = dayjs();
  const bookingStartTime = dayjs(startTime).tz(bookingTimezone);
  const cardClasses = classNames(
    "flex w-full flex-col text-enc-black rounded-2xl",
    classname
  );
  function formatClpNumber(number: number) {
    let formattedNumber = number.toString().replace(/\D/g, "");
    return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };
  const getFormattedDate = (text?: string) => {
    return (
      dayjs(text).tz(bookingTimezone).format("D") +
      " de " +
      dayjs(text).tz(bookingTimezone).format("MMMM") +
      " del " +
      dayjs(text).tz(bookingTimezone).format("YYYY") +
      " - " +
      dayjs(text).tz(bookingTimezone).format("HH:mm") +
      " Hrs " +
      "(" +
      bookingTimezone +
      ")"
    );
  };
  return (
    <div className={cardClasses}>
      <div className="flex w-full mb-2 items-center">
        <UserIcon className="h-4 w-4 mr-3 text-enc-secondary-light"></UserIcon>
        <span className="text-sm">{professionalName}</span>
      </div>
      {(location || locationType === "online") && (
        <div>
          <div className="flex w-full mb-2 items-center">
            <CalendarIcon className="h-4 w-4 mr-3 text-enc-secondary-light"></CalendarIcon>
            <span className="text-sm">{getFormattedDate(startTime)}</span>
          </div>
          <div className="flex w-full mb-2 items-center">
            {locationType === "online" ? (
              <VideoCameraIcon className="h-4 w-4 mr-3 text-enc-secondary-light"></VideoCameraIcon>
            ) : (
              <LocationMarkerIcon className="h-4 w-4 mr-3 text-enc-secondary-light"></LocationMarkerIcon>
            )}
            <span className="text-sm">
              {locationType === "online" ? "Sesión online" : location}
            </span>
          </div>
          <div className="flex w-full mb-2 items-center">
            <ClockIcon className="h-4 w-4 mr-3 text-enc-secondary-light"></ClockIcon>
            <span className="text-sm">{duration} minutos</span>
          </div>
        </div>
      )}
      <div className="flex w-full mb-2 items-center">
        <CurrencyDollarIcon className="h-4 w-4 mr-3 text-enc-secondary-light"></CurrencyDollarIcon>
        <span className="text-sm">
          {price === 0 ? "Sin costo" : `$${formatClpNumber(price || 0)}`}
        </span>
      </div>
    </div>
  );
};

export default BookingDetailsCardLocation;
