import { FC } from "react";
import { UserIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import Loader from "./Loader";

export type LeftContentProps = {
  loading?: boolean;
  img?: string;
  containerClassName?: string;
  firstText?: string;
  firstTextClass?: string;
  secondText?: string;
  secondTextClass?: string;
};

const LeftContent: FC<LeftContentProps> = ({
  loading,
  img,
  containerClassName,
  firstText,
  firstTextClass,
  secondText,
  secondTextClass,
}) => {
  const containerClass = classNames("flex sm:space-x-5", containerClassName);
  const firstTextClasses = classNames("mb-2.5 capitalize", firstTextClass);
  return (
    <div className={containerClass}>
      <div className="flex-shrink-0">
        {(img && !loading && (
          <div className="flex align-middle justify-center overflow-hidden h-20 w-20 mx-auto border border-enc-grey rounded-10px">
            <img
              height="auto"
              width="auto"
              className="object-contain"
              src={img}
              alt=""
            />
          </div>
        )) ||
          (!img && !loading && (
            <div className="mx-auto h-20 w-20 rounded-10px flex items-center justify-center bg-gray-500">
              <UserIcon className="h-12 w-12 text-white" />
            </div>
          )) ||
          (loading && <Loader className="h-20 w-20" />)}
      </div>
      <div className="mt-2.5 pt-0.5 sm:text-left">
        {loading ? (
          <div className={firstTextClass}>
            <Loader className="mb-4 w-32" />
          </div>
        ) : (
          <p className={firstTextClasses}>{firstText}</p>
        )}
        {loading ? (
          <div className={secondTextClass}>
            <Loader className="w-32" />
          </div>
        ) : (
          <p className={secondTextClass}>{secondText}</p>
        )}
      </div>
    </div>
  );
};

export default LeftContent;
