import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import GeneralView, { GeneralViewProps } from "../../GeneralView";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import { useForm } from "react-hook-form";

type FormType = {
  email: string;
};

const ErrorView: FC<GeneralViewProps> = (props) => {
  const navigate = useNavigate();
  const handleReload = () => {
    window.location.reload();
  };
  return (
    <GeneralView {...props}>
      <Button className="mt-9" onClick={handleReload}>
        Volver a ingresar mail
      </Button>
    </GeneralView>
  );
};

const TokenRequest: FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<number>();
  const url = "https://encuadrado.com/api/v1/send-bookings-access-link";

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormType>();

  const sendEmail = (data: FormType) => {
    setLoading(true);
    axios
      .post<string>(url, {
        email: data.email,
      })
      .then(() => {
        setLoading(false);
        navigate("/confirmacion");
      })
      .catch((ex) => {
        const error = ex.response.status;
        setError(error);
        setLoading(false);
      });
  };

  if (error === 500)
    return (
      <GeneralView
        title="Ha ocurrido un problema"
        subtitle="Por favor intenta más tarde."
        professionalLogin={false}
      />
    );
  else if (error === 400)
    return (
      <ErrorView
        title="Aún no tienes consultas asociadas a este mail"
        subtitle="Si quieres probar con otro mail, haz click en el siguiente botón."
      />
    );

  return (
    <GeneralView subtitle="Ingresa el email con el que te hayas agendado con un profesional de Encuadrado.">
      <form className="w-full" onSubmit={handleSubmit(sendEmail)}>
        <Input
          {...register("email", { required: true })}
          containerClassName="mt-4"
          label="Mail"
          type="email"
          name="email"
          id="email"
          placeholder="ejemplo@mail.com"
        />
        {errors.email && (
          <span className="text-xs text-center text-enc-org">
            Debes ingresar un mail correcto
          </span>
        )}
        <Button
          className="mt-4 w-full"
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Ingresar mail
        </Button>
      </form>
    </GeneralView>
  );
};

export default TokenRequest;
