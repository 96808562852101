import classNames from "classnames";
import { FC } from "react";

export type LoaderProps = {
  className?: string;
};
const Loader: FC<LoaderProps> = ({ className }) => {
  const loaderClass = classNames(
    "animate-pulse h-4 bg-gray-200 rounded",
    className
  );

  return <p className={loaderClass} />;
};

export default Loader;
