import { FC } from "react";

const Container: FC = ({ children }) => (
  <div className="flex justify-center">
    <div className="w-full lg:max-w-1000px mt-4 xs:mt-50px mx-4 xs:mx-6">
      {children}
    </div>
  </div>
);

export default Container;
