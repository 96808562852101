import { FC } from 'react';
import { MenuAlt2Icon, XIcon, ChevronLeftIcon } from '@heroicons/react/outline';

export type HeaderProps = {
  link?: string;
  className?: string;
  clickMenu?: () => void;
  navbarOpen?: boolean;
  secondContentOpen?: boolean;
};

const Header: FC<HeaderProps> = ({
  clickMenu,
  navbarOpen,
  secondContentOpen,
}) => {
  return (
    <div className='border-b flex w-full p-4 justify-between top-0 items-center shadow-sm fixed bg-white z-20'>
      {navbarOpen ? (
        <button onClick={clickMenu}>
          <XIcon className='header-menu-button w-6 h-6 text-enc-secondary-base sm:hidden'></XIcon>
        </button>
      ) : secondContentOpen ? (
        <ChevronLeftIcon className='w-6 h-6 text-enc-secondary-base sm:invisible'></ChevronLeftIcon>
      ) : (
        <button onClick={clickMenu}>
          <MenuAlt2Icon className='header-menu-button w-6 h-6 text-enc-secondary-base sm:hidden'></MenuAlt2Icon>
        </button>
      )}

      <img
        src='https://encuadrado-assets.s3.sa-east-1.amazonaws.com/img/navbar-logo-enc-sin-margen.svg'
        alt='Enc logo'
      ></img>
      <div className='w-8 h-8 rounded-32px border invisible'>
        <img
          className='rounded-32px'
          src='https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png'
        ></img>
      </div>
    </div>
  );
};

export default Header;
