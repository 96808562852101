import { FC } from "react";
import Card from "../Card";

const CreateAccountCard: FC = () => {
  return (
    <Card
      className="bg-gray-600 border-0"
      leftContent={
        <div>
          <p className="text-base font-bold text-white">
            ¿También eres un profesional independiente?
          </p>
          <p className="mt-4 mb-22px text-base font-light text-white">
            Crea tu cuenta y olvídate de las tareas administrativas ahora
          </p>
          <a
            className="inline-block h-fit-content py-0.5 px-5 mb-1.5 rounded-15px text-base font-medium bg-enc-grey-btn text-white"
            href={"https://encuadrado.com/login"}
          >
            Crear Cuenta
          </a>
        </div>
      }
    />
  );
};
export default CreateAccountCard;
