import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NavbarWithHeader from '../../../components/templates/NavbarWithHeader';
import Input from '../../../components/Input';
import Link from '../../../components/Link';
import { CustomerData } from '../types';
import Loader from '../../Customers/BookingsHistory/Loader';
import axios from 'axios';

export type ConfigurationViewProps = {
  className?: string;
};

const ConfigurationView: FC<ConfigurationViewProps> = () => {
  const navigate = useNavigate();
  const [customerData, setCustomerData] = useState<CustomerData>();
  const { token } = useParams<string>();

  const customerURL = 'https://encuadrado.com/api/v1/customer';

  useEffect(() => {
    axios
      .post<CustomerData>(
        customerURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data);
      })
      .catch((ex) => {});
  }, []);

  if (customerData) {
    return (
      <NavbarWithHeader
        containerClasses='w-full sm:w-2/3 md:w-1/2 lg:w-1/3'
        healthInsuranceURL={
          customerData.link_onboarding_health_insurance_provider
        }
        selectedBtn='settings'
      >
        <span className='text-2xl font-semibold mb-4'>Configuraciones</span>
        <div className='flex items-center justify-between mb-4'>
          <span className='text-lg font-medium'>Información personal</span>
          <div
            onClick={() =>
              navigate(`/${token}/configuraciones/informacion-personal/editar`)
            }
          >
            <Link>Editar</Link>
          </div>
        </div>
        <Input
          className='w-full'
          label='Correo'
          type='email'
          disabled
          value={customerData.customer_email}
        />
      </NavbarWithHeader>
    );
  }
  return (
    <NavbarWithHeader healthInsuranceURL='' selectedBtn='attentions'>
      <div className='flex w-full flex-col'>
        <span className='text-2xl font-semibold mb-4'>Configuraciones</span>
        <Loader className='mb-4 w-36'></Loader>
        <Loader className='mb-4 w-24'></Loader>
        <Loader className='w-36'></Loader>
      </div>
    </NavbarWithHeader>
  );
};

export default ConfigurationView;
