import { FC, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { ParamsProps } from "../utils";
import WelcomeCard from "./WelcomeCard";
import ProfessionalCard from "./ProfessionalCard";
import { ProfessionalData } from "./utils";
import CreateAccountCard from "./CreateAccountCard";
import Pagination from "../../../../components/Pagination";
import Card from "../Card";
import Container from "../Container";
import LeftContent from "../LeftContent";
import Loader from "../Loader";
import { left_content_first_text, left_content_second_text } from "./styles";
import GeneralView, { GeneralViewProps } from "../../../GeneralView";
import Button from "../../../../components/Button";

export type CustomerData = {
  customer_email: string;
  customer_full_name: string;
  link_onboarding_health_insurance_provider: string;
  health_insurance_provider: string | null;
  health_insurance_provider_onboarded: boolean;
};

const ErrorView: FC<GeneralViewProps> = (props) => {
  const navigate = useNavigate();
  return (
    <GeneralView {...props}>
      <Button className="mt-9" onClick={() => navigate("/ingreso-mail")}>
        Volver a ingresar mail
      </Button>
    </GeneralView>
  );
};

const ProffesionalsList: FC = () => {
  const { token } = useParams<ParamsProps>();
  const defaultCustomerData: CustomerData = {
    customer_email: "",
    customer_full_name: "",
    link_onboarding_health_insurance_provider: "",
    health_insurance_provider: "",
    health_insurance_provider_onboarded: false,
  };
  const [customerData, setCustomerData] =
    useState<CustomerData>(defaultCustomerData);
  const [customerDataLoading, setCustomerDataLoading] = useState<boolean>(true);
  const [customerDataError, setCustomerDataError] = useState<number>();

  const [professionalsData, setProfessionalsData] =
    useState<ProfessionalData[]>();
  const [professionalsLoading, setProfessionalsLoading] =
    useState<boolean>(true);
  const [professionalsDataError, setProfessionalsDataError] =
    useState<number>();

  const professionalsURL = "https://encuadrado.com/api/v1/professionals";
  const customerURL = "https://encuadrado.com/api/v1/customer";

  useEffect(() => {
    axios
      .post<CustomerData>(
        customerURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data);
        setCustomerDataLoading(false);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setCustomerDataError(error);
        setCustomerDataLoading(false);
      });
    axios
      .post<ProfessionalData[]>(
        professionalsURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setProfessionalsData(response.data);
        setProfessionalsLoading(false);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setProfessionalsDataError(error);
        setProfessionalsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (customerDataError === 500 || professionalsDataError === 500)
    return (
      <GeneralView
        title="Ha ocurrido un problema"
        subtitle="Por favor intenta más tarde."
        professionalLogin={false}
      />
    );
  else if (customerDataError === 401 || professionalsDataError === 401)
    return (
      <ErrorView
        title="El link de acceso expiró"
        subtitle="Por motivos de seguridad tienes que ingresar nuevamente tu mail para recibir otro link de acceso."
      />
    );
  else if (professionalsData?.length === 0)
    return (
      <ErrorView
        title="Aún no tienes consultas asociadas a este mail"
        subtitle="Si quieres probar con otro mail, haz click en el siguiente botón."
      />
    );
  return (
    <Container>
      <WelcomeCard
        loading={professionalsLoading || customerDataLoading}
        customerName={customerData.customer_full_name}
        isHealthInsuranceConnected={
          customerData.health_insurance_provider_onboarded
        }
        healthInsuranceLink={
          customerData.link_onboarding_health_insurance_provider
        }
      />
      {professionalsLoading ? (
        <Card
          leftContent={
            <LeftContent
              loading={professionalsLoading}
              firstTextClass={left_content_first_text}
              secondTextClass={left_content_second_text}
            />
          }
          rightContent={<Loader className="w-24 h-6" />}
          footer={{
            color: "gray-50",
            leftContent: <Loader className="w-32 h-5 mb-2" />,
          }}
        />
      ) : (
        <>
          {professionalsData && professionalsData.length > 5 ? (
            <Pagination
              data={professionalsData}
              component={ProfessionalCard}
              dataLimit={4}
            />
          ) : (
            professionalsData?.map((professional, i) => (
              <ProfessionalCard key={i} data={professional} />
            ))
          )}
          <CreateAccountCard />
        </>
      )}
    </Container>
  );
};
export default ProffesionalsList;
