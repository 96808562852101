
/** 
  * Enables easy tracking usage and convention since is mutable and can be passed properties.
  * @param {string} eventName The name of the event on segment.
  * @param {object} properties properties passed to segment tracking.
  * - example `trackHelper("Booking Started", {...properties})`
  * - properties can be any object with non undefined data
  **/
export const trackHelper = (eventName, properties) => {

  // force analytics loading
  if (typeof analytics !== undefined){

  try{
    window.analytics.track(
      eventName,
      {
        type: "track",
        event: eventName,
        context: {
          ref: document.referrer,
          href: window.location.href,
        },
        properties:properties,
      }
    );
  }catch (error){
    return;
  }

} else {
          setTimeout(function () {
              trackHelper(eventName,properties);
        }, 100)
      }
  }

export const trackPageView = () => {
    // force analytics loading
    if (typeof analytics !== undefined){
    try{
      window.analytics.page();
    }catch (error){
      return;
    }
  } else {
            setTimeout(function () {
              trackPageView();
          }, 100)
        }
    }

/** 
* Enables easy tracking usage and convention since is mutable and can be passed properties.
* @param {string} eventName The name of the event on segment.
* @param {object} properties properties passed to segment tracking.
* @param {object} fn callback for redirect.
* - example `trackHelperRedirect("Booking Started", {...properties}, ()=>redirect_to_agendar())`
* - properties can be any object with non undefined data
**/
export const  trackHelperRedirect = (eventName, properties, fn) => {
  try {
      window.analytics.track(eventName, properties, fn());
      }catch (error) {
      fn();
      }
}


export const redirectToNextPage = (page, isAndroidWebview = false) => {
const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
if (isSafari) {
  setTimeout(function() {
    window.location.assign(page);
  }, 500);
} else if (isAndroidWebview) {
  window.open(`intent:${page}#Intent;end`, '_blank');
} else {
  window.location.assign(page);
}
}