import { FC, ReactNode } from "react";
import classNames from "classnames";
import Loader from "../Loader";

export type ButtonProps = {
  loading?: boolean;
  containerClassName?: string;
  className?: string;
  linkTo?: string;
  text?: ReactNode;
};

const Button: FC<ButtonProps> = ({
  loading,
  containerClassName,
  className,
  linkTo,
  text,
}) => {
  const containerClass = classNames(
    "flex items-center justify-center",
    containerClassName
  );
  const btnClass = classNames(
    "flex justify-center mt-4 sm:mt-0 px-4 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-2xl text-gray-700 bg-white hover:bg-gray-50",
    className
  );

  return (
    <div className={containerClass}>
      {loading ? (
        <Loader className="w-32 h-9" />
      ) : (
        <a href={linkTo} target="_blank" className={btnClass}>
          {text}
        </a>
      )}
    </div>
  );
};

export default Button;
