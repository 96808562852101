import { FC } from "react";
import GeneralView from "../../GeneralView";
import { Link } from "react-router-dom";
import { link_classes } from "../../../styles";

const LogoutView: FC = () => {
  return (
    <GeneralView
      title="Sesión cerrada"
      subtitle={
        <>
          <Link className={link_classes} to={"/ingreso-mail"}>
            ¿Quieres acceder con otro email?
          </Link>
        </>
      }
      professionalLogin={false}
    />
  );
};

export default LogoutView;
