import classNames from "classnames";
import { FC, ReactNode } from "react";

export type CardProps = {
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  className?: string;
  children?: ReactNode;
  footer?: {
    color: "gray-50" | "white";
    leftContent?: ReactNode;
    middleContent?: ReactNode;
    rightContent?: ReactNode;
  };
};

const Card: FC<CardProps> = ({
  leftContent,
  rightContent,
  className,
  children,
  footer,
}) => {
  const cardClass = classNames(
    "rounded-lg bg-white overflow-hidden border border-enc-grey rounded-10px",
    className
  );

  const footerClass = classNames(
    `border-t border-enc-grey flex text-base p-4 font-medium flex w-full bg-${footer?.color} items-center`,
    { "justify-center": footer?.middleContent },
    { "justify-between": footer?.rightContent }
  );

  return (
    <div className="py-2 leading-5">
      <div className={cardClass}>
        <div className="p-4">
          {(leftContent || rightContent) && (
            <div className="flex flex-col-reverse sm:flex-row sm:justify-between">
              <div>{leftContent}</div>
              <div className="flex flex-col items-end">{rightContent}</div>
            </div>
          )}
          {children}
        </div>
        {footer && (
          <div className={footerClass}>
            <span>{footer.leftContent}</span>
            <span>{footer.middleContent}</span>
            <span className="text-21px font-semibold text-right">
              {footer.rightContent}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
export default Card;
