import { FC } from "react";
import { ChevronRightIcon } from "@heroicons/react/outline";

export type ProfessionalListCardProps = {
  className?: string;
  name?: string;
  profession?: string;
  imgLink?: string;
  link?: string;
  hasDebt?: string;
  specificCard?: boolean | true;
  professionalEmail?: string;
};

const ProfessionalListCard: FC<ProfessionalListCardProps> = ({
  className,
  name,
  profession,
  imgLink,
  link,
  hasDebt,
  specificCard,
  professionalEmail,
}) => {
  return (
    <div
      className={`${
        !specificCard ? "hover:bg-enc-background-salt-gray cursor-pointer" : ""
      } flex w-full flex-col`}
      onClick={
        !specificCard ? () => (window.location.href = link || "") : undefined
      }
    >
      <div className="flex w-full p-2 cursor:pointer">
        <div className="flex w-20 h-20 rounded-lg justify-center items-center overflow-hidden">
          <img
            src={
              imgLink ||
              "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png"
            }
            alt=""
            height="auto"
            width="auto"
            className={
              specificCard
                ? "object-cover w-auto h-auto max-w-full max-h-full rounded-lg"
                : "object-contain rounded-lg"
            }
          ></img>
        </div>
        <div
          className={
            specificCard
              ? "flex grow w-[calc(100%-80px)] truncate justify-between items-center"
              : "flex grow justify-between items-center"
          }
        >
          <div className=" flex flex-col ml-4">
            <span className="text-enc-black font-semibold mb-3">{name}</span>
            <span className="text-enc-secondary-base">{profession}</span>
            <span className="text-enc-secondary-base">
              {!specificCard
                ? hasDebt
                  ? "Por pagar"
                  : "Sin deudas"
                : professionalEmail
                ? professionalEmail
                : ""}
            </span>
          </div>
          <div className="flex ml-9">
            {!specificCard && (
              <ChevronRightIcon className="h-9 w-9 text-enc-secondary-base hover:cursor-pointer"></ChevronRightIcon>
            )}
          </div>
        </div>
        <hr></hr>
      </div>
      {!specificCard && <hr className="mt-4"></hr>}
    </div>
  );
};

export default ProfessionalListCard;
