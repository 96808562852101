import { FC, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import EncNavbarBtn from './button';
import {
  ArrowLeftIcon,
  DocumentIcon,
  HomeIcon,
  InformationCircleIcon,
  OfficeBuildingIcon,
  CogIcon,
} from '@heroicons/react/solid';
import classNames from 'classnames';

export type EncNavbarProps = {
  visible?: boolean;
  healthInsuranceURL: string;
  selectedBtn: 'home' | 'attentions' | 'reimbursements' | 'help' | 'settings';
  setIsNavbarOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EncNavbar: FC<EncNavbarProps> = ({
  visible,
  setIsNavbarOpen,
  healthInsuranceURL,
  selectedBtn,
}) => {
  const { token } = useParams<string>();
  const urlPrefix = `/${token}/home`;
  const ref = useRef<any>(null);

  const navbarClasses = classNames(
    'enc-navbar sm:flex flex-col fixed left-0 w-3/5 sm:w-52 h-full sm:h-screen shadow-sm border bg-white z-10 p-4 mt-64px',
    !visible && 'hidden'
  );

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        !ref?.current?.contains(e.target) &&
        !e?.target?.closest('.header-menu-button')
      ) {
        setIsNavbarOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick, false);
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  }, []);

  return (
    <div className={navbarClasses} ref={ref}>
      <EncNavbarBtn
        className='mb-3'
        icon={<HomeIcon />}
        selected={selectedBtn === 'home'}
        url={urlPrefix}
      >
        Inicio
      </EncNavbarBtn>

      <EncNavbarBtn
        className='mb-3'
        icon={<DocumentIcon />}
        selected={selectedBtn === 'attentions'}
        url={`${urlPrefix}/profesionales`}
      >
        Tus atenciones
      </EncNavbarBtn>

      <EncNavbarBtn
        className='mb-3'
        icon={<OfficeBuildingIcon />}
        selected={selectedBtn === 'reimbursements'}
        url={healthInsuranceURL}
        newTab={true}
      >
        Reembolsos
      </EncNavbarBtn>

      <EncNavbarBtn
        className='mb-3'
        icon={<InformationCircleIcon />}
        selected={selectedBtn === 'help'}
        url='https://ayuda.encuadrado.com/es/collections/3419981-para-pacientes-clientes'
        newTab={true}
      >
        Ayuda
      </EncNavbarBtn>

      <EncNavbarBtn
        className='mb-3'
        icon={<CogIcon />}
        selected={selectedBtn === 'settings'}
        url={`/${token}/configuraciones`}
      >
        Configuraciones
      </EncNavbarBtn>

      <hr className='mb-3'></hr>
      <EncNavbarBtn
        icon={<ArrowLeftIcon />}
        selected={false}
        url={`${urlPrefix}/logout`}
      >
        Cerrar sesión
      </EncNavbarBtn>
    </div>
  );
};

export default EncNavbar;
