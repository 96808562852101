import { FC } from "react";
import classNames from "classnames";

export type CardProps = {
  debt?: boolean;
  className?: string;
  link?: string;
};

const Tag: FC<CardProps> = ({ debt, className, link }) => {
  const type = debt ? "error" : "success";
  const text = type === "error" ? "Por pagar" : "Pagado";
  const bgColor = type === "error" ? "bg-enc-error" : "bg-enc-success";

  const tagClass = classNames(
    `h-fit-content py-1 px-3.5 rounded-lg text-base font-medium text-white`,
    className,
    bgColor,
    { "cursor-pointer": link }
  );
  return (
    <>
      {link ? (
        <a href={link} className={tagClass}>
          {text}
        </a>
      ) : (
        <span className={tagClass}>{text}</span>
      )}
    </>
  );
};

export default Tag;
