import { forwardRef, InputHTMLAttributes } from 'react';
import classNames from 'classnames';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  containerClassName?: string;
  className?: string;
  label?: string;
};

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ containerClassName, className, label, ...rest }, ref) => {
    const inputClasses = classNames(
      'shadow-sm focus:ring-enc-blue-500 focus:border-enc-blue-500 block w-full sm:text-base border-gray-300 rounded-2xl disabled:bg-enc-background-lighter-gray',
      className
    );
    return (
      <div className={containerClassName}>
        <label htmlFor='label' className='block text-sm font-medium mb-2'>
          {label}
        </label>
        <input ref={ref} className={inputClasses} {...rest} />
      </div>
    );
  }
);

export default Input;
