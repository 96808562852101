import { FC, useEffect, useState } from "react";
import { ChevronLeftIcon } from "@heroicons/react/solid";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import ProfessionalCard from "./ProfessionalCard";
import Button from "./Button";
import BookingCard from "./BookingCard";
import { BookingData, ProfessionalData } from "./utils";
import Card from "../Card";
import Container from "../Container";
import Loader from "../Loader";
import BookingCardContent from "./BookingCardContent";
import Pagination from "../../../../components/Pagination";
import GeneralView from "../../../GeneralView";

export interface IBookingData {
  bookings: BookingData[];
  professional_data: ProfessionalData;
}

export type ParamsProps = {
  token: string;
  professionalEmail: string;
};

const BookingsList: FC = () => {
  const { token } = useParams<ParamsProps>();
  const { professionalEmail } = useParams<ParamsProps>();
  const navigate = useNavigate();

  const defaultBookingsData: IBookingData = {
    bookings: [],
    professional_data: {
      professional_booking_link: "",
      professional_business_type: "",
      professional_email: "",
      professional_image_url: "",
      professional_name: "",
    },
  };
  const [bookingsData, setBookingsData] =
    useState<IBookingData>(defaultBookingsData);
  const [bookingsLoading, setBookingsLoading] = useState<boolean>(true);
  const [bookingsDataError, setBookingsDataError] = useState<number>();

  const baseURL = `https://encuadrado.com/api/v1/bookings/${professionalEmail}`;

  useEffect(() => {
    axios
      .post<IBookingData>(
        baseURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setBookingsData(response.data);
        setBookingsLoading(false);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setBookingsDataError(error);
        setBookingsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (bookingsDataError === 500)
    return (
      <GeneralView
        title="Ha ocurrido un problema"
        subtitle="Por favor intenta más tarde."
        professionalLogin={false}
      />
    );

  return (
    <Container>
      <ChevronLeftIcon
        className="h-12 cursor-pointer"
        color="#2D66F5"
        onClick={() => navigate(`/${token}/profesionales`)}
      />
      <ProfessionalCard
        loading={bookingsLoading}
        data={bookingsData.professional_data}
        sessionsQuantity={bookingsData.bookings.length}
      />
      {bookingsLoading ? (
        <Card
          footer={{
            color: "gray-50",
            leftContent: <Loader className="w-24 h-5" />,
            rightContent: <Loader className="w-28 h-6" />,
          }}
        >
          <BookingCardContent
            topLeftContent={<Loader className="w-48" />}
            topRightContent={<Loader className="w-24 h-6" />}
            bottomLeftContent={<Loader className="mb-4 w-48 h-5" />}
            bottomRightContent={
              <Button
                loading={bookingsLoading}
                containerClassName="w-full sm:w-auto"
                className="w-full sm:w-auto"
              />
            }
          />
        </Card>
      ) : (
        <>
          {bookingsData.bookings.length > 5 ? (
            <Pagination
              data={bookingsData.bookings}
              component={BookingCard}
              dataLimit={4}
            />
          ) : (
            <>
              {bookingsData.bookings.map((booking, i) => (
                <BookingCard key={i} data={booking} />
              ))}
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default BookingsList;
