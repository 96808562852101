import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ParamsProps } from './HomeView';
import { AdjustmentsIcon } from '@heroicons/react/outline';
import FooterHome from '../components/FooterHome';
import ProfessionalListCard from './Customers/ProfessionalListCard';
import axios from 'axios';
import Loader from './Customers/BookingsHistory/Loader';
import { CustomerData } from './Customers/BookingsHistory/ProfessionalsList';
import { trackPageView } from '../analytics/masterTracking.js';
import NavbarWithHeader from '../components/templates/NavbarWithHeader';

export type ProfessionalListViewProps = {
  className?: string;
};

type ProfessionalData = {
  professional_name?: string;
  professional_business_type?: string;
  professional_booking_link?: string;
  professional_image_url?: string;
  has_debt?: string;
  professional_email?: string;
};

const ProfessionalListView: FC<ProfessionalListViewProps> = () => {
  const [customerData, setCustomerData] = useState<CustomerData>();
  const { token } = useParams<ParamsProps>();
  const [professionalsData, setProfessionalsData] =
    useState<ProfessionalData[]>();

  const professionalsURL = "https://encuadrado.com/api/v1/professionals";
  const customerURL = "https://encuadrado.com/api/v1/customer";

  useEffect(() => {
    document.title = 'Tus profesionales | Portal';
  }, []);
  
  useEffect(() => {
    axios
      .post<ProfessionalData[]>(
        professionalsURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setProfessionalsData(response.data);
      })
      .catch((ex) => {});
    axios
      .post<CustomerData>(
        customerURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data);
      });
  }, []);

  useEffect(() => {
    trackPageView();
  }, []);

  if (professionalsData && customerData) {
    return (
      <>
        <NavbarWithHeader
          healthInsuranceURL={
            customerData.link_onboarding_health_insurance_provider
          }
          selectedBtn='attentions'
        >
          <div className='flex w-full justify-between'>
            <span className='text-2xl font-semibold '>Profesionales</span>
            <AdjustmentsIcon className='w-8 h-8 text-enc-secondary-base hidden'></AdjustmentsIcon>
          </div>
          <div className='flex flex-col w-full mt-4'>
            {professionalsData?.map((professional) => (
              <ProfessionalListCard
                name={professional.professional_name}
                profession={professional.professional_business_type}
                link={`https://app.encuadrado.com/${
                  token || ''
                }/home/profesionales/${
                  professional.professional_email || ''
                }/sesiones`}
                imgLink={professional.professional_image_url}
                hasDebt={professional.has_debt}
              ></ProfessionalListCard>
            ))}
          </div>
        </NavbarWithHeader>
        <FooterHome
          link='https://encuadrado.com'
          text='¿También atiendes en sesiones uno a uno?'
          linkText='Prueba Encuadrado ahora'
        ></FooterHome>
      </>
    );
  }
  return (
    <NavbarWithHeader healthInsuranceURL='' selectedBtn='attentions'>
      <div className='flex w-full flex-col'>
        <span className='text-2xl font-semibold mb-3'>Profesionales</span>
        <Loader className='mb-4 w-36'></Loader>
        <Loader className='mb-4 w-24'></Loader>
        <Loader className='w-36'></Loader>
      </div>
    </NavbarWithHeader>
  );
};

export default ProfessionalListView;
