import { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CalendarIcon } from '@heroicons/react/solid';
import FooterHome from '../components/FooterHome';
import { ParamsProps } from './HomeView';
import { UpcomingBookingsData } from './HomeView';
import axios from 'axios';
import UpcomingBookingCard from './Customers/UpcomingBookingCard';
import Loader from './Customers/BookingsHistory/Loader';
import BookingDetailsCard from './Customers/BookingDetailsCard';
import { BookingDetailsData } from './HomeView';
import dayjs from 'dayjs';
import Pagination from '../components/Pagination';
import { CustomerData } from './Customers/BookingsHistory/ProfessionalsList';
import { trackPageView } from '../analytics/masterTracking.js';
import NavbarWithHeader from '../components/templates/NavbarWithHeader';

export type ActivityViewProps = {
  className?: string;
};

const ActivityView: FC<ActivityViewProps> = () => {
  const [secondContentOpen, setSecondContentOpen] = useState(false);
  const [upcomingBookingsData, setUpcomingBookingsData] =
    useState<UpcomingBookingsData[]>();
  const [bookingDetailsData, setBookingDetailsData] =
    useState<BookingDetailsData>({});
  const { token } = useParams<ParamsProps>();
  const [customerData, setCustomerData] = useState<CustomerData>();
  const customerURL = "https://encuadrado.com/api/v1/customer";
  const now = dayjs();

  const bookingsURL =
    "https://encuadrado.com/api/v1/bookings/get-upcoming-bookings";
  const bookingDetailsURL = "https://encuadrado.com/api/v1/get-booking-details";

  useEffect(() => {
    document.title = 'Tus próximas sesiones | Portal';
  }, []);

  useEffect(() => {
    axios
      .post<UpcomingBookingsData[]>(
        bookingsURL,
        {
          client_email_token: token,
          get_all_bookings: false,
          get_future_events: true,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setUpcomingBookingsData(response.data);
      })
      .catch((ex) => {});
    axios
      .post<CustomerData>(
        customerURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPageView();
  }, []);

  const getBookingDetails = (bookingToken: string) => {
    axios
      .post<BookingDetailsData>(
        bookingDetailsURL,
        {
          client_email_token: token,
          booking_token: bookingToken,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setSecondContentOpen(true);
        setBookingDetailsData(response.data);
      })
      .catch((ex) => {
        setSecondContentOpen(true);
      });
  };
  return (
    <>
      <BookingDetailsCard
        shown={secondContentOpen}
        data={bookingDetailsData}
        backBtnFunction={() => setSecondContentOpen(false)}
      ></BookingDetailsCard>

      <NavbarWithHeader
        containerClasses='p-4'
        healthInsuranceURL={
          customerData?.link_onboarding_health_insurance_provider || ''
        }
        selectedBtn='attentions'
      >
        <span className='text-enc-black font-semibold text-2xl mb-3'>
          Tus próximas sesiones
        </span>
        {upcomingBookingsData && upcomingBookingsData.length > 5 ? (
          <Pagination
            data={upcomingBookingsData
              .filter((booking) =>
                now.isBefore(
                  dayjs(booking.booking_start_time).tz(booking.booking_timezone)
                )
              )
              .map((booking) => ({
                ...booking,
                detailsFunction: getBookingDetails,
                secondContentOpen: secondContentOpen,
                className: 'mb-4',
              }))}
            component={UpcomingBookingCard}
            dataLimit={4}
          />
        ) : upcomingBookingsData && upcomingBookingsData?.length > 0 ? (
          upcomingBookingsData?.map(
            (booking, i) =>
              now <
                dayjs(booking.booking_start_time).tz(
                  booking.booking_timezone
                ) && (
                <UpcomingBookingCard
                  key={i}
                  data={{
                    ...booking,
                    detailsFunction: getBookingDetails,
                    secondContentOpen: secondContentOpen,
                    className: 'mb-4',
                  }}
                />
              )
          )
        ) : upcomingBookingsData?.length === 0 ? (
          <div className='p-5 flex w-full justify-center items-center flex-col rounded-2xl bg-enc-background-light-gray text-enc-secondary-base border border-enc-background-light-gray enc-background-salt-gray'>
            <CalendarIcon className='w-11 h-11 mb-3'></CalendarIcon>
            <span className=''>No tienes sesiones programadas</span>
          </div>
        ) : (
          <div className='p-5 flex w-full flex-col rounded-2xl text-enc-secondary-base border border-enc-background-light-gray enc-background-salt-gray'>
            <Loader className='w-6/12'></Loader>
            <Loader className='mt-3 w-3/12'></Loader>
          </div>
        )}
      </NavbarWithHeader>
      {!secondContentOpen && (
        <FooterHome
          link='https://encuadrado.com'
          text='¿También atiendes en sesiones uno a uno?'
          linkText='Prueba Encuadrado ahora'
        ></FooterHome>
      )}
    </>
  );
};

export default ActivityView;
