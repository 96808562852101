import { FC, ReactNode, useState, useEffect } from 'react';
import axios from 'axios';
import Link from '../components/Link';
import UpcomingBookingCard from './Customers/UpcomingBookingCard';
import { useParams, useNavigate } from 'react-router-dom';
import Loader from './Customers/BookingsHistory/Loader';
import ProfessionalFooterCard from './Customers/ProfessionalFooterCard';
import { ProfessionalData } from './Customers/BookingsHistory/ProfessionalsList/utils';
import FooterHome from '../components/FooterHome';
import BookingDetailsCard from './Customers/BookingDetailsCard';
import dayjs from 'dayjs';
import PendingDebtsCard from '../components/PendingDebtsCard';
import GeneralView, { GeneralViewProps } from './GeneralView';
import Button from '../components/Button';
import { trackPageView } from '../analytics/masterTracking.js';
import NavbarWithHeader from '../components/templates/NavbarWithHeader';

export type HomeViewProps = {
  header?: ReactNode;
  navbar?: ReactNode;
};

type CustomerData = {
  customer_email: string;
  customer_full_name: string;
  link_onboarding_health_insurance_provider: string;
  health_insurance_provider: string | null;
  health_insurance_provider_onboarded: boolean;
};

const ErrorView: FC<GeneralViewProps> = (props) => {
  const navigate = useNavigate();
  return (
    <GeneralView {...props}>
      <Button className="mt-9" onClick={() => navigate("/ingreso-mail")}>
        Volver a ingresar mail
      </Button>
    </GeneralView>
  );
};

export type UpcomingBookingsData = {
  id?: number;
  booking_start_time?: string;
  booking_end_time?: string;
  booking_timezone: string;
  service_name?: string;
  currency?: string;
  timezone?: string;
  price?: number;
  payment_link?: string;
  payment_date?: string;
  reschedule_link?: string;
  review_link?: string;
  boleta_link?: string;
  is_paid?: boolean;
  office_address?: string;
  videocall_link?: string;
  sessions_package_link?: string;
  booking_token?: string;
  intake_form_link?: string;
  schedule_link?: string;
  className?: string;
  secondContentOpen?: boolean;
  detailsFunction?: (bookingToken: string) => void;
  digital_content_link?: string;
};

export type BookingDetailsData = {
  name?: string;
  description?: string;
  location?: string;
  duration?: number;
  locationType?: string;
  isPaid?: boolean;
  invoiceLink?: string;
  startTime?: string;
  bookingTimezone?: string;
  endTime?: string;
  inTakeFormLink?: string;
  reviewLink?: string;
  scheduleLink?: string;
  rescheduleLink?: string;
  paymentLink?: string;
  professionalImgLink?: string;
  professionalName?: string;
  price?: number;
  digitalContentLink?: string;
};

export type ParamsProps = {
  token: string;
  professionalEmail: string;
};

const defaultCustomerData: CustomerData = {
  customer_email: "",
  customer_full_name: "",
  link_onboarding_health_insurance_provider: "",
  health_insurance_provider: "",
  health_insurance_provider_onboarded: false,
};

const HomeView: FC<HomeViewProps> = () => {
  const { token } = useParams<ParamsProps>();
  const [secondContentOpen, setSecondContentOpen] = useState(false);
  const [upcomingBookingsData, setUpcomingBookingsData] =
    useState<UpcomingBookingsData[]>();
  const [upcomingBookingsLoading, setUpcomingBookingsLoading] = useState(false);
  const [nextBookingData, setNextBookingData] =
    useState<UpcomingBookingsData[]>();
  const [nextBookingLoading, setNextBookingLoading] = useState(false);
  const [professionalsData, setProfessionalsData] =
    useState<ProfessionalData[]>();
  const [professionalsLoading, setProfessionalsLoading] =
    useState<boolean>(true);
  const [professionalsDataError, setProfessionalsDataError] =
    useState<number>();
  const [bookingDetailsData, setBookingDetailsData] =
    useState<BookingDetailsData>({});
  const [bookingDetailsDataError, setBookingDetailsDataError] =
    useState<number>();
  const [customerData, setCustomerData] =
    useState<CustomerData>(defaultCustomerData);
  const [customerDataLoading, setCustomerDataLoading] = useState<boolean>(true);
  const [customerDataError, setCustomerDataError] = useState<number>();
  const now = dayjs();
  const upcomingBoookingsURL =
    "https://encuadrado.com/api/v1/bookings/get-upcoming-bookings";
  const professionalsURL = "https://encuadrado.com/api/v1/professionals";
  const defaultImgUrl =
    "https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png";
  const bookingDetailsURL = "https://encuadrado.com/api/v1/get-booking-details";
  const customerURL = "https://encuadrado.com/api/v1/customer";

  useEffect(() => {
    document.title = 'Home | Portal';
  }, []);
  
  useEffect(() => {
    axios
      .post<UpcomingBookingsData[]>(
        upcomingBoookingsURL,
        {
          client_email_token: token,
          get_all_bookings: false,
          get_future_events: false,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setUpcomingBookingsData(response.data);
        setUpcomingBookingsLoading(false);
      })
      .catch((ex) => {
        setUpcomingBookingsLoading(false);
      });
    axios
      .post<UpcomingBookingsData[]>(
        upcomingBoookingsURL,
        {
          client_email_token: token,
          get_all_bookings: false,
          get_future_events: true,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setNextBookingData(response.data);
        setNextBookingLoading(false);
      })
      .catch((ex) => {
        setNextBookingLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    axios
      .post<ProfessionalData[]>(
        professionalsURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setProfessionalsData(response.data);
        setProfessionalsLoading(false);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setProfessionalsDataError(error);
        setProfessionalsLoading(false);
      });
    axios
      .post<CustomerData>(
        customerURL,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setCustomerData(response.data);
        setCustomerDataLoading(false);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setCustomerDataError(error);
        setCustomerDataLoading(false);
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    trackPageView();
  }, []);

  const getBookingDetails = (bookingToken: string) => {
    axios
      .post<BookingDetailsData>(
        bookingDetailsURL,
        {
          client_email_token: token,
          booking_token: bookingToken,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setSecondContentOpen(true);
        setBookingDetailsData(response.data);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setSecondContentOpen(true);
        setBookingDetailsDataError(error);
      });
  };

  const getNextBooking = (nextBookingData: UpcomingBookingsData[]) => {
    let minBooking = nextBookingData[0];
    return minBooking;
  };
  const getPastBookings = (upcomingBookingsData: UpcomingBookingsData[]) => {
    const pastBookings = upcomingBookingsData;
    return pastBookings;
  };
  const nextBooking = getNextBooking(nextBookingData || []);
  const pastBookings = getPastBookings(upcomingBookingsData || []);

  const isDataLoading =
    upcomingBookingsLoading ||
    nextBookingLoading ||
    professionalsLoading ||
    customerDataLoading;

  if (
    customerDataError === 500 ||
    professionalsDataError === 500 ||
    bookingDetailsDataError === 500
  )
    return (
      <GeneralView
        title="Ha ocurrido un problema"
        subtitle="Por favor intenta más tarde."
        professionalLogin={false}
      />
    );
  else if (
    customerDataError === 401 ||
    professionalsDataError === 401 ||
    bookingDetailsDataError === 401
  )
    return (
      <ErrorView
        title="El link de acceso expiró"
        subtitle="Por motivos de seguridad tienes que ingresar nuevamente tu mail para recibir otro link de acceso."
      />
    );
  else if (professionalsData?.length === 0)
    return (
      <ErrorView
        title="Aún no tienes consultas asociadas a este mail"
        subtitle="Si quieres probar con otro mail, haz click en el siguiente botón."
      />
    );

  return (
    <>
      <BookingDetailsCard
        shown={secondContentOpen}
        data={bookingDetailsData}
        backBtnFunction={() => setSecondContentOpen(false)}
      ></BookingDetailsCard>
      <NavbarWithHeader
      containerClasses='p-4'
        healthInsuranceURL={
          customerData.link_onboarding_health_insurance_provider
        }
        selectedBtn='home'
      >
        <div
          className={
            secondContentOpen
              ? 'w-full flex-col hidden sm:flex relative'
              : 'flex w-full flex-col relative'
          }
        >
          <span className="mb-5 mt-4 text-2xl font-normal">
            {isDataLoading ? (
              <Loader className="w-32"></Loader>
            ) : (
              <span className="capitalize">
                {`👋 Hola ${customerData.customer_full_name.split(" ")[0]}`}
              </span>
            )}
          </span>
          <div className="flex w-full flex-col-reverse xl:flex-row">
            <div className="flex w-full flex-col">
              {isDataLoading ? (
                <div className="p-5 flex w-full flex-col rounded-2xl text-enc-secondary-base border border-enc-background-light-gray enc-background-salt-gray">
                  <Loader className="w-6/12"></Loader>
                  <Loader className="mt-3 w-3/12"></Loader>
                </div>
              ) : nextBookingData && nextBookingData.length ? (
                nextBooking && (
                  <div className="flex w-full flex-col mb-4">
                    <div className="flex w-full justify-between mb-4 items-center">
                      <span className="text-lg font-bold ">
                        Tu próxima sesión
                      </span>
                      <Link
                        link={`https://app.encuadrado.com/${token}/home/actividad`}
                        inSamePage={true}
                        children="Ver todas"
                      ></Link>
                    </div>
                    <UpcomingBookingCard
                      data={{
                        ...nextBooking,
                        className: "mb-4",
                        detailsFunction: getBookingDetails,
                        secondContentOpen: secondContentOpen,
                      }}
                    />
                  </div>
                )
              ) : (
                nextBookingData?.length === 0 && null
              )}
              {isDataLoading ? (
                <div className="p-5 flex w-full flex-col rounded-2xl text-enc-secondary-base border border-enc-background-light-gray enc-background-salt-gray mt-3">
                  <Loader className="w-6/12"></Loader>
                  <Loader className="mt-3 w-3/12"></Loader>
                </div>
              ) : upcomingBookingsData && upcomingBookingsData.length ? (
                pastBookings &&
                pastBookings[0] && (
                  <div className="flex w-full flex-col">
                    <div className="flex w-full justify-between mb-4 items-center">
                      <span className="text-lg font-bold">
                        Sesiones pasadas
                      </span>
                      <Link
                        link={`https://app.encuadrado.com/${token}/home/profesionales`}
                        inSamePage={true}
                        children="Ver todas"
                      ></Link>
                    </div>
                    {pastBookings?.slice(0, 2).map(
                      (booking) =>
                        now >
                          dayjs(booking.booking_start_time).tz(
                            booking.booking_timezone
                          ) && (
                          <UpcomingBookingCard
                            data={{
                              ...booking,
                              className: "mb-4",
                              detailsFunction: getBookingDetails,
                              secondContentOpen: secondContentOpen,
                            }}
                          />
                        )
                    )}
                  </div>
                )
              ) : (
                upcomingBookingsData?.length === 0 && null
              )}
            </div>
            <PendingDebtsCard className="xl:ml-3 mb-4 xl:mb-0 md:w-29rem flex"></PendingDebtsCard>
          </div>
          <div className="flex w-full justify-between items-center mt-4 hidden">
            <span className="text-2xl font-semibold text-enc-black">
              Profesionales
            </span>
            {professionalsData && professionalsData?.length >= 3 && (
              <Link
                inSamePage={true}
                link={`https://app.encuadrado.com/${token}/home/profesionales`}
                children="Ver todos"
              ></Link>
            )}
          </div>
          <div className="flex flex-col w-full mt-4 xl:flex-row hidden ">
            {professionalsData?.slice(0, 4).map(
              (professional, index) =>
                index % 2 === 0 && (
                  <div className="flex w-full mb-4 sm:w-auto justify-center">
                    <ProfessionalFooterCard
                      name={professional.professional_name}
                      imageLink={
                        professional.professional_image_url || defaultImgUrl
                      }
                      profession={professional.professional_business_type}
                      className="w-6/12 flex mr-3 sm:ml-3 "
                      professionalEmail={professional.professional_email}
                    ></ProfessionalFooterCard>
                    {professionalsData[index + 1] && (
                      <ProfessionalFooterCard
                        name={professionalsData[index + 1].professional_name}
                        imageLink={
                          professionalsData[index + 1].professional_image_url ||
                          defaultImgUrl
                        }
                        profession={
                          professionalsData[index + 1]
                            .professional_business_type
                        }
                        className={"w-6/12 hidden sm:flex sm:ml-3 sm:mr-3 "}
                        professionalEmail={
                          professionalsData[index + 1].professional_email
                        }
                      ></ProfessionalFooterCard>
                    )}
                  </div>
                )
            )}
          </div>
        </div>
      </NavbarWithHeader>
      {!secondContentOpen && (
        <FooterHome
          link='https://encuadrado.com'
          text='¿También atiendes en sesiones uno a uno?'
          linkText='Prueba Encuadrado ahora'
        ></FooterHome>
      )}
    </>
  );
};

export default HomeView;
