import { FC } from "react";
import Link from "./Link";
import classNames from "classnames";

export type FooterHomeProps = {
  className?: string;
  text?: string;
  linkText?: string;
  link?: string;
};

const FooterHome: FC<FooterHomeProps> = ({
  className,
  text,
  linkText,
  link,
}) => {
  const footerClass = classNames(
    "pt-4 pb-12 bg-enc-background-lighter-gray flex w-full items-center flex-col bottom-0 w-full sm:w-[calc(100%-13rem)] sm:left-[208px] relative",
    className
  );
  return (
    <div className={footerClass}>
      <span className="text-enc-secondary-base text-sm mb-2">{text}</span>
      <Link
        className="text-sm text-enc-primary-base flex justify-center hover:cursor-pointer hover:no-underline hover:bg-enc-primary-background p-2 rounded-lg"
        link={link ?? "https://encuadrado.com"}
        children={linkText}
      ></Link>
    </div>
  );
};

export default FooterHome;
