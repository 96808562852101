import { FC, ReactNode, useEffect } from "react";
import Link from "../components/Link";

export type GeneralViewProps = {
  title?: ReactNode;
  subtitle?: ReactNode;
  professionalLogin?: boolean;
};

const GeneralView: FC<GeneralViewProps> = ({
  title,
  subtitle,
  children,
  professionalLogin = true,
}) => {

  useEffect(() => {
    document.title = 'Login | Portal';
  }, []);

  return (
    <div className="flex justify-center pt-88px text-gray-600 leading-5">
      <div className="w-332px flex flex-col">
        <img
          className="mb-92px"
          src="https://uploads-ssl.webflow.com/603cb1f27d94f73ea44ed45f/61631ea26419a453f3e5687c_Logo%20Encuadrado.png"
          alt="Workflow"
        />
        {title && <span className="mb-5 text-2xl font-normal">{title}</span>}
        <span className="text-base font-normal">{subtitle}</span>
        {children}
        {professionalLogin && (
          <>
            <span className="text-base mt-64 sm:mt-72 text-center">
              ¿Buscas entrar a tu cuenta profesional?
            </span>
            <Link link="https://encuadrado.com/login" className="text-center">
              Iniciar sesión como profesional de Encuadrado
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default GeneralView;
