import { FC } from "react";
import Link from "./Link";

export type PendingDebt = {
  className?: string;
  debtNumber?: string;
  currency?: string;
  professionalName?: string;
  paymentLink?: string;
};

const PendingDebt: FC<PendingDebt> = ({
  className,
  debtNumber,
  currency,
  professionalName,
  paymentLink,
}) => {
  return (
    <>
      <div className="flex w-full mt-4 justify-between items-center">
        <div className="flex flex-col">
          <span className="font-semibold text-base text-enc-secondary-dark mb-2">
            {currency}{" "}
            {debtNumber?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
          </span>
          <span className="font-normal text-sm text-enc-secondary-base">
            {professionalName}
          </span>
        </div>
        <Link
          className="whitespace-nowrap"
          children="Ver deuda"
          link={paymentLink}
        ></Link>
      </div>
      <hr className="mt-4 border-enc-light-gray"></hr>
    </>
  );
};

export default PendingDebt;
