import { FC, useEffect, useState } from "react";
import classNames from "classnames";
import PendingDebt from "./PendingDebt";
import { useParams } from "react-router-dom";
import { ParamsProps } from "../views/Customers/BookingsHistory/utils";
import {
  ExclamationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/outline";
import axios from "axios";
import Loader from "../views/Customers/BookingsHistory/Loader";


export type PendingDebtsCard = {
  className?: string;
};

type PendingDebt = {
  debt_number?: string;
  currency?: string;
  professional_name?: string;
  payment_link?: string;
};

const PendingDebtsCard: FC<PendingDebtsCard> = ({ className }) => {
  const cardClass = classNames(
    `bg-enc-salt-gray rounded-2xl flex flex-col w-full p-5 h-fit`,
    className
  );
  const { token } = useParams<ParamsProps>();
  const [pendingDebts, setPendingDebts] = useState<PendingDebt[]>();
  const [pendingDebtsLoading, setPendingDebtsLoading] = useState<boolean>(true);
  const [pendingDebtsError, setPendingDebtsError] = useState<number>();

  const pendingDebtsUrl = "https://encuadrado.com/api/v1/pending-debts";

  useEffect(() => {
    axios
      .post<PendingDebt[]>(
        pendingDebtsUrl,
        {
          client_email_token: token,
        },
        { withCredentials: true }
      )
      .then((response) => {
        setPendingDebts(response.data);
        setPendingDebtsLoading(false);
      })
      .catch((ex) => {
        const error = ex.response.status;
        setPendingDebtsError(error);
        setPendingDebtsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (pendingDebtsLoading) {
    return (
      <div className={cardClass}>
        <Loader className="w-32" />
        <Loader className="mt-4 w-48" />
        <Loader className="mt-4 w-32" />
        <div className="flex w-full space-between"></div>
      </div>
    );
  }
  return (
    <div className={cardClass}>
      {!pendingDebts ||
        (pendingDebts.length === 0 && (
          <div className="flex w-full justify-center">
            <CheckCircleIcon className="w-11 h-11 text-enc-green-base mb-2 mr-3"></CheckCircleIcon>
          </div>
        ))}
      <div className="flex w-full space-between">
        {pendingDebts && pendingDebts.length > 0 && (
          <ExclamationCircleIcon className="w-7 h-7 text-enc-system-warning mb-2 mr-3"></ExclamationCircleIcon>
        )}

        {pendingDebts && pendingDebts.length > 0 ? (
          <span className="font-semibold text-lg text-enc-black">
            Tienes deudas pendientes
          </span>
        ) : (
          <div className="flex w-full justify-center">
            <span className="font-normal text-lg text-enc-secondary-base">
              Por aquí no tienes deudas.
            </span>
          </div>
        )}
      </div>
      {pendingDebts?.slice(0, 2).map((debt) => (
        <PendingDebt
          debtNumber={debt.debt_number}
          currency={debt.currency}
          professionalName={debt.professional_name}
          paymentLink={debt.payment_link}
        ></PendingDebt>
      ))}
    </div>
  );
};

export default PendingDebtsCard;
