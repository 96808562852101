import { FC, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  loading?: boolean;
  encType?: string;
};

const Button: FC<ButtonProps> = ({
  className,
  loading,
  children,
  encType = 'primary',
  ...rest
}) => {
  const buttonClasses = classNames(
    `h-42px min-w-124px flex items-center justify-center px-4 rounded-2xl text-base font-normal hover:cursor-pointer focus:outline-none focus:shadow-primary-button disabled:bg-enc-primary-light disabled:cursor-not-allowed`,
    encType === 'primary' && 'text-white bg-enc-primary-base',
    encType === 'secondary' &&
      'text-enc-primary-base border-button border-enc-primary-base',
    loading && 'hover:none',
    !loading && encType === 'primary' && 'hover:bg-enc-primary-dark',
    !loading &&
      encType === 'secondary' &&
      'hover:border-enc-primary-dark text-enc-primary-dark hover:bg-enc-primary-background',
    className
  );
  return (
    <>
      <button className={buttonClasses} {...rest}>
        {loading ? (
          <div className='flex items-center justify-center space-x-2 animate-pulse'>
            <div className='w-2 h-2 bg-white rounded-full'></div>
            <div className='w-2 h-2 bg-white rounded-full'></div>
            <div className='w-2 h-2 bg-white rounded-full'></div>
          </div>
        ) : (
          children
        )}
      </button>
    </>
  );
};

export default Button;
