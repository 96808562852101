import { FC, ReactNode } from "react";
import Link from "../../../../components/Link";

export type BookingCardContentProps = {
  topLeftContent?: ReactNode;
  topRightContent?: ReactNode;
  bottomLeftContent?: ReactNode;
  bottomRightContent?: ReactNode;
  videocallLink?: string;
  officeAddress?: string;
};

const BookingCardContent: FC<BookingCardContentProps> = ({
  topLeftContent,
  topRightContent,
  bottomLeftContent,
  bottomRightContent,
  videocallLink,
  officeAddress,
}) => {
  return (
    <div className="flex flex-col">
      <div className="mb-5 flex justify-between items-center w-full">
        <span className="w-1/2 xs:w-4/5 font-normal text-base text-black">
          {topLeftContent}
        </span>
        {topRightContent}
      </div>
      <div className="flex flex-col items-center sm:flex-row justify-between w-full">
        <span className="font-medium text-lg">{bottomLeftContent}</span>
        {bottomRightContent}
      </div>
      {videocallLink ? (
        <Link className="mt-5" link={videocallLink}>
          Acceder a la videollamada
        </Link>
      ) : (
        ""
      )}
      {officeAddress ? (
        <span className="mt-5 text-base">En {officeAddress}</span>
      ) : (
        ""
      )}
    </div>
  );
};

export default BookingCardContent;
