import { FC, ReactNode } from "react";
import Card from "../Card";
import Button from "./Button";
import Tag from "../Tag";
import { StarIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { BookingData } from "./utils";
import BookingCardContent from "./BookingCardContent";
import es from "dayjs/locale/es";
import Link from "../../../../components/Link";
import PrimaryButton from "../../../../components/Button";

type BookingCardProps = {
  data: BookingData;
};

type BookingCardButtonProps = {
  text: ReactNode;
  link: string;
};

const BookingCardButton: FC<BookingCardButtonProps> = ({ text, link }) => (
  <Button
    linkTo={link}
    containerClassName="w-full sm:w-auto"
    className="w-full sm:w-auto"
    text={text}
  />
);

const BookingCard: FC<BookingCardProps> = ({ data }) => {
  const {
    boleta_link,
    booking_end_time,
    booking_start_time,
    booking_timezone,
    currency,
    is_paid,
    office_address,
    payment_date,
    payment_link,
    price,
    reschedule_link,
    review_link,
    service_name,
    sessions_package_link,
    videocall_link,
  } = data;
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.locale({
    ...es,
    weekStart: 1,
  });
  const now = dayjs();
  const bookingStartTime = dayjs(booking_start_time).tz(booking_timezone);
  const bookingEndTime = dayjs(booking_end_time).tz(booking_timezone);
  const boookingIsNotDone = now <= bookingEndTime;

  const getFormattedDate = (text: string) => {
    return (
      dayjs(text).tz(booking_timezone).format("D") +
      " de " +
      dayjs(text).tz(booking_timezone).format("MMMM") +
      " del " +
      dayjs(text).tz(booking_timezone).format("YYYY") +
      " - " +
      dayjs(text).tz(booking_timezone).format("HH:mm") +
      " hrs"
    );
  };

  return (
    <Card
      footer={
        (is_paid &&
          boleta_link && {
            color: "gray-50",
            leftContent: <Link link={boleta_link}>Ver boleta</Link>,
          }) ||
        (!is_paid && {
          color: "gray-50",
          leftContent: price ? (
            <PrimaryButton
              onClick={() => (window.location.href = payment_link)}
            >
              Pagar
            </PrimaryButton>
          ) : sessions_package_link ? (
            <PrimaryButton
              onClick={() => (window.location.href = sessions_package_link)}
            >
              Agendar siguiente
            </PrimaryButton>
          ) : (
            ""
          ),
          rightContent: `${currency.toUpperCase()} ${price
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`,
        }) ||
        undefined
      }
    >
      <BookingCardContent
        topLeftContent={
          <>
            {getFormattedDate(booking_start_time)}
            {payment_date ? (
              <>
                {" "}
                ·{" "}
                <span className="text-enc-success">
                  Pagado el {getFormattedDate(payment_date)}
                </span>
              </>
            ) : (
              ""
            )}
          </>
        }
        topRightContent={
          price ? (
            <Tag
              className="ml-4"
              debt={!is_paid}
              link={!is_paid ? payment_link : ""}
            />
          ) : (
            ""
          )
        }
        bottomLeftContent={service_name}
        bottomRightContent={
          <>
            {now < bookingStartTime ? (
              <BookingCardButton
                text={<span>Reagendar</span>}
                link={reschedule_link}
              />
            ) : (
              review_link ?
              <BookingCardButton
                text={
                  <div className="flex items-center">
                    <StarIcon className="h-18px mb-0.5" />
                    &nbsp;<span>Evaluar</span>
                  </div>
                }
                link={review_link}
              /> : ""
            )}
          </>
        }
        videocallLink={boookingIsNotDone ? videocall_link : ""}
        officeAddress={boookingIsNotDone ? office_address : ""}
      />
    </Card>
  );
};

export default BookingCard;
